.advanced-settings-container .MuiAccordionSummary-root-510 {
  background-color: #e5e5e5;
}

.advanced-settings-form button {
  border: none;
  background-color: inherit;
  color: #3898ec;
  font-weight: bold;
}

.feild-title {
  display: block;
  margin-bottom: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
}
