@value mobile,
landscapeLayout from "./media.css";

.navigation-links-container {
  /* grid-column: 1 / -1;
  grid-row: 5; */
  /* align-self: end; */
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: height 0.5s;
  /* removed 'height: 100%;' Hacky decison for iframe embed accessing buttons*/
}

.navigation-links-container-iframe {
  grid-column: 1 / -1;
  grid-row: 5;
  align-self: end;
  width: 60%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: height 0.5s;
  height: 100%;
}

/* Placeholder so babel-plugin-react-css-modules can resolve these classes */
.navigation-links-collapsed,
.navigation-links-open {
}

.nav-inside-container {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  /* Trigger scroll on overflow */
  max-height: 100%;
  overflow-y: auto;
}

.collapse-button {
  grid-row: 4;
  grid-column: 1 / -1;
  cursor: pointer;
}

.collapse-button svg {
  /* margin: 0 10px;
  fill: #fff;
  transition: fill 0.5s, transform 0.5s;
  filter: drop-shadow(1px 1px 4px black); */
}

.collapse-button:hover svg {
  /* fill: var(--primary-color); */
}

.navigation-links-container .title {
  padding: 1%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.15em;
  color: black;
  /* text-shadow: 2px 2px 8px black; */
}

.navigation-link-alt {
  display: flex;
  /* flex-grow and flex-basis are set by JS depending on number of columns */
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-height: min-content;
  line-height: 20px;
  color: black;
  font-size: var(--small-font-size);
  padding: 0px 8px;
  cursor: pointer;
  background-color: white;
  border: 1px solid var(--primary-color);
  border-radius: 12px;
}

.navigation-link-alt:hover {
  background: #f3f4f6;
  /* color: white; */
  /* border-color: var(--primary-color); */
}

.navigation-link-alt:last-of-type {
  margin-bottom: 0;
}

.navigation-link-alt .letter {
  color: white;
  align-items: center;
  background: var(--primary-color);
  font-weight: 700;
  border-radius: 100%;
  display: flex;
  flex: none;
  height: 28px;
  justify-content: center;
  line-height: 20px;
  margin-right: 15px;
  text-align: center;
  width: 28px;
}

.navigation-link-alt .content {
  display: block;
  flex-grow: 1;
  line-height: 20px;
  padding: 9px 0px;
  text-align: left;
}

@media mobile {
  .navigation-links-container {
    width: 100%;
    padding: 10px 20px;
  }
}

@media landscapeLayout {
  .navigation-links-container {
    grid-column: 2;
    grid-row: 3 / -1;
    align-self: center;
    justify-content: flex-start;
  }

  .collapse-button svg {
    display: none;
  }
}

/* .location-chip {
    color: var(--primary-color);

    background-color: rgba(128, 128, 128, 0.056);
    border-radius: 500px;
    border: 1px solid rgba(128, 128, 128, 0.194);
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 2px;
    padding-bottom: 2px;

} */
