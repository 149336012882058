/* styles adapted from https://github.com/tabler/tabler react/blob/master/src/Tabler.css custom-switch styles for double sided switch */

.switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}

.switchInput {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.switchIndicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: #e9ecef;
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid rgba(0, 40, 100, 0.12);
  transition: 0.3s border-color, 0.3s background-color;
}

.switchIndicator:before {
  content: '';
  position: absolute;
  height: calc(1.25rem - 4px);
  width: calc(1.25rem - 4px);
  top: 1px;
  left: 1px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

/*
.switchInput:checked ~ .switchIndicator {
  background: #467fcf;
}
*/

.switchInput:checked ~ .switchIndicator:before {
  left: calc(1rem + 1px);
}

.switchInput:focus ~ .switchIndicator {
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
  /* border-color: #467fcf; */
}

.descriptionRight,
.descriptionLeft {
  color: #6e7687;
  transition: 0.3s color;
}

.descriptionLeft {
  order: -1;
  margin-right: 0.5rem;
}

.descriptionRight {
  margin-left: 0.5rem;
}

.switchInput:not(:checked) ~ .descriptionLeft,
.switchInput:checked ~ .descriptionRight {
  color: #495057;
}
