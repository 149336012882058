.admin-container {
  margin: 10px 5% 0% 5%;
}

.admin-container h1 {
  color: black;
}

.admin-container input[type='search'] {
  border: none;
  border-radius: 4px;
  width: 75%;
  background-color: #efefef;
  height: 50px;
  font-size: 24px;
  margin: 3px 20px 8px 15px;
}

.admin-card-container {
  margin-left: 1%;
}

.admin-container-card {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  margin: 4px 3px 0px 3px;
  color: white;
  height: 150px;
  width: 49%;
  overflow-x: scroll;
}

.admin-container-card:hover {
  opacity: 0.7;
}

.admin-container-card h2 {
  font-size: 40px;
  margin-top: 55px;
}

.admin-login-container {
  text-align: center;
  margin-top: 30px;
}

.admin-login-container h1 {
  color: black;
  font-size: 40px;
}

.admin-login-container input[type='password'] {
  width: 40vw;
  height: 40px;
  margin-bottom: 24px;
  border: none;
  border-radius: 6px;
  background-color: #efefef;
}

.admin-login-container button {
  width: 20vw;
  height: 50px;
  border: none;
  border-radius: 6px;
  color: white;
  background-color: #3175b1;
  font-size: 26px;
  font-weight: 600;
}

.dashboard-container {
  margin: 30px 5% 0% 5%;
}

.dashboard-container .inline-block {
  display: inline-block;
  width: 50%;
}

.dashboard-container h1 {
  color: black;
}

.dashboard-container .MuiAppBar-colorPrimary {
  background-color: white;
  color: #3175b1;
  border-radius: 2px;
}

.dashboard-container .PrivateTabIndicator-colorSecondary-3 {
  border-bottom: 4px solid #3175b1;
}

.dashboard-container .MuiBox-root {
  border-radius: 2px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.dashboard-container .MuiTab-wrapper {
  font-weight: bold;
}

.dashboard-staff-container input[type='search'] {
  width: 460px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #efefef;
}

.dashboard-staff-container button {
  border: none;
  background-color: inherit;
  height: 40px;
  font-size: 20px;
  color: #3175b1;
}

.dashboard-staff-container .employee {
  padding: 10px 0px 10px 20px;
  margin-bottom: 5px;
  color: white;
  background-color: #3175b1;
  font-size: 18px;
  border-radius: 4px;
}

.dashboard-staff-container .employee:hover {
  opacity: 0.7;
}

.dashboard-staff-container .employees {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.dashboard-staff-form {
  padding: 25px;
  background-color: inherit;
}

.dashboard-staff-form h3 {
  color: #3175b1;
}

.dashboard-staff-form input[type='text'],
.dashboard-staff-form input[type='email'] {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: #efefef;
  margin-bottom: 5px;
}

.dashboard-staff-form input[type='submit'],
.dashboard-staff-form input[type='reset'] {
  border: none;
  border-radius: 4px;
  background-color: white;
  padding: 8px 20px 8px 20px;
  margin: 3px;
  font-size: 16px;
  font-weight: bold;
}

.dashboard-staff-form input[type='submit'] {
  color: #3175b1;
  border: 1px solid #3175b1;
}

.dashboard-staff-form input[type='submit']:hover {
  background-color: #3175b1;
  color: white;
}

.dashboard-staff-form input[type='reset'] {
  color: red;
  border: 1px solid red;
}

.dashboard-staff-form input[type='reset']:hover {
  background-color: red;
  color: white;
}

.dashboard-apt-container {
  height: 280px;
  width: 44vw;
}

.dashboard-apt-container .MuiRating-sizeLarge {
  font-size: 3rem;
}

.dashboard-apt-container .inline-block {
  padding-right: 60px;
}

.dashboard-apt-container .inline-block h1 {
  line-height: 6px;
  font-weight: bold;
  font-size: 40px;
}

.dashboard-apt-container .inline-block p {
  color: #393939;
}

.dashboard-qrcode-container {
  height: 280px;
  width: 45vw;
}

.dashboard-qrcode-container .float-left {
  padding-left: 60px;
}

.dashboard-qrcode-container .float-right {
  padding: 50px 100px 0px 0px;
}

.dashboard-qrcode-container button {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 50px;
  color: white;
  margin-bottom: 10px;
  font-size: 20px;
}

.dashboard-qrcode-container .btn-outline {
  border: 2px solid #3175b1;
  background-color: white;
  color: #3175b1;
}

.dashboard-qrcode-container .btn-fill {
  border: 2px solid #3175b1;
  background-color: #3175b1;
}

.dashboard-badge {
  display: inline-block;
  background-color: #f0f5f9;
  color: #3175b1;
  /* border: 1px solid #3175B1; */
  border-radius: 50px;
  padding: 7px 15px 7px 15px;
  margin: 0px 5px 8px 5px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.dashboard-badge-container {
  margin-bottom: 13px;
}

.dashboard-employee-container {
  margin: 0px 5% 0% 5%;
  color: black;
}

.employee-navigation {
  color: #3175b1;
  font-size: 28px;
  padding-bottom: 30px;
}

.employee-navigation .most-left {
  padding-right: 15px;
}

.employee-navigation .left {
  padding-left: 15px;
}

.employee-navigation .center {
  font-weight: bold;
  padding-right: 120px;
  padding-left: 120px;
}

.employee-navigation .right {
  padding-right: 15px;
}

.employee-navigation .most-right {
  padding-left: 15px;
}

.employee-navigation span:not(.center):hover {
  opacity: 0.7;
}

.dashboard-settings {
  display: inline-block;
  margin-left: 10px;
  color: #3175b1;
}

.dashboard-settings button {
  border: none;
  background-color: inherit;
}

.dashboard-settings-form {
  padding: 20px;
  color: black;
}

.dashboard-settings-form h2 {
  color: #3175b1;
}

.dashboard-settings-form table {
  table-layout: fixed;
}

.dashboard-settings-form td {
  vertical-align: top;
}

.dashboard-settings-form label {
  font-size: 20px;
}

.dashboard-settings-form input,
.dashboard-settings-form select {
  height: 36px;
  border: none;
  border-radius: 4px;
  background-color: #efefef;
  margin-bottom: 3px;
}

.dashboard-settings-form select {
  width: 100%;
}

.dashboard-settings-form input[type='file'] {
  height: inherit;
  border-radius: 0;
  width: 171px;
}

.dashboard-settings-form input[type='submit'],
.dashboard-settings-form input[type='reset'] {
  border: none;
  border-radius: 4px;
  background-color: white;
  padding: 0px 15px 0px 15px;
  margin: 12px 3px 0px 3px;
  font-size: 16px;
  font-weight: bold;
}

.dashboard-settings-form input[type='submit'] {
  color: #3175b1;
  border: 1px solid #3175b1;
}

.dashboard-settings-form input[type='submit']:hover {
  background-color: #3175b1;
  color: white;
}

.dashboard-settings-form input[type='reset'] {
  color: red;
  border: 1px solid red;
}

.dashboard-settings-form input[type='reset']:hover {
  background-color: red;
  color: white;
}

.dashboard-settings-form .add {
  background-color: inherit;
  border: none;
  color: #3175b1;
  font-size: 22px;
  font-weight: bolder;
}

.dashboard-settings-form .badge-container {
  margin-top: 12px;
}

.dashboard-settings-form .badge-container p {
  line-height: 8px;
}

.dashboard-settings-form .badge-container button {
  background-color: inherit;
  border: none;
  color: red;
  font-size: 20px;
  font-weight: bolder;
}

.dashboard-settings-form .badge-container button:hover {
  opacity: 0.5;
}

.admin-container button {
  margin-top: 20px;
  background-color: inherit;
  border: none;
  color: #3175b1;
  font-size: 24px;
}

.add-apartment-container {
  margin: 30px 28% 0% 28%;
  color: black;
}

.add-apartment-container label {
  font-size: 20px;
}

.add-apartment-container input[type='text'],
.add-apartment-container input[type='url'],
.add-apartment-container input[type='number'],
.add-apartment-container select,
.add-apartment-container input[type='file'] {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: #efefef;
  margin-bottom: 5px;
}

.add-apartment-container input[type='file'] {
  height: inherit;
  border-radius: 0px;
}

.add-apartment-container input[type='submit'] {
  margin: 20px 0px 20px 0px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding: 8px 40px 8px 40px;
  border: none;
  border-radius: 6px;
  background-color: #3175b1;
  color: white;
}

.add-apartment-container input[type='submit']:hover {
  opacity: 0.7;
}

.settings-button {
  width: 250px;
  padding: 5px;
}

.settings-button button {
  border: none;
  border-radius: 50px;
  color: white;
  background-color: #3175b1;
  padding: 10px 0px 10px 0px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
}

.sms-reaction-icon {
  opacity: 0.5;
  filter: alpha(opacity=40);
}

.sms-reaction-icon:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.delete-apt {
  margin: 20px 0px 5px 66%;
  background-color: inherit;
  border: none;
  color: red;
  font-size: 11px;
  text-align: right;
}

.sms-form input[type='tel'],
.sms-form input[type='text'],
.sms-form input[type='email'] {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #3175b1;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
}

.sms-form input[type='submit'] {
  padding: 8px 40px;
  font-size: 16px;
  background-color: #3175b1;
  color: white;
  border-radius: 4px;
  border: none;
}

.sms-form input[type='submit']:hover {
  opacity: 0.9;
}

.sms-form .sms {
  width: 100%;
  height: 100%;
  background-color: #248bf5;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  resize: none;
  position: relative;
  word-wrap: break-word;
}

.sms-form .sms::before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #248bf5 0%, #248bf5 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.sms-form .sms::after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.sms-form .email {
  background-color: #efefef;
  border-radius: 8px;
  padding: 10px;
}

.sms-form button {
  background-color: inherit;
  border: none;
  color: #3175b1;
  padding: 8px 0px;
  font-size: 16px;
  float: right;
}

@media only screen and (max-width: 900px) {
  .admin-container input[type='search'] {
    width: 90%;
  }

  .admin-container button {
    margin: 0;
  }

  .admin-card-container {
    margin: 0;
  }

  .admin-container-card {
    width: 98%;
  }

  .admin-login-container input[type='password'] {
    width: 80vw;
  }

  .admin-login-container button {
    width: 60vw;
  }

  .dashboard-container {
    margin-top: 10px;
  }

  .dashboard-container h1 {
    text-align: center;
  }

  .dashboard-container .inline-block {
    display: block;
    width: 100%;
  }

  .dashboard-container .float-left {
    float: none !important;
    display: inline-block;
  }

  .dashboard-container .float-right {
    float: none !important;
    display: block;
  }

  .dashboard-qrcode-container {
    height: inherit;
    width: inherit;
    text-align: center;
  }

  .dashboard-qrcode-container .float-left {
    padding: 0;
  }

  .dashboard-qrcode-container .float-right {
    padding: 10px 55px 0px 55px;
    margin-bottom: 10px;
  }

  .dashboard-apt-container {
    height: inherit;
    width: inherit;
    margin-top: 20px;
    text-align: center;
  }

  .dashboard-apt-container .inline-block {
    padding: 0;
  }

  .dashboard-staff-container input[type='search'] {
    width: 250px;
  }

  .employee-navigation {
    font-size: 16px;
    padding-bottom: 20px;
  }

  .employee-navigation .most-left {
    padding-right: 3px;
  }

  .employee-navigation .left {
    padding-left: 3px;
  }

  .employee-navigation .center {
    font-weight: bold;
    padding-right: 10px;
    padding-left: 10px;
  }

  .employee-navigation .right {
    padding-right: 3px;
  }

  .employee-navigation .most-right {
    padding-left: 3px;
  }

  .dashboard-employee-table th {
    padding-left: 10px;
    font-size: 16px;
  }

  .dashboard-employee-table td {
    height: inherit;
    padding-left: 10px;
  }

  .add-apartment-container {
    margin: 30px 5% 0% 5%;
  }

  .delete-apt {
    width: 40%;
  }

  .sms-form {
    padding: 20px;
  }

  .sms-form input[type='tel'],
  .sms-form input[type='text'] {
    margin-top: 0px;
  }

  .sms-form input[type='submit'] {
    font-size: 15px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .sms-form button {
    font-size: 15px;
  }
}
