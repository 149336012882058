.initial-message-bubble {
  position: relative;
  /* background: #fff; */
  border: 1px solid rgba(0, 0, 0, 0.086);
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s linear;
  z-index: 2147483647;
  background-color: rgba(255, 255, 255, 0.86);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* box-shadow: rgba(211, 211, 211, 0.326) 0px 4px 20px; */
}

.chatbot-button-close {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -12px;
  top: -12px;
  height: 24px;
  width: 24px;
  background: rgba(86, 80, 80, 0.7);
  border-radius: 15px;
}
