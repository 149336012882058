.AgentScreen {
  position: relative;
  background: black;
  color: white;
  justify-content: center;
  display: flex;
  flex: 1;
}

.AgentScreen .error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: rgb(196, 64, 77);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 120;
}

.AgentScreen .loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 110;
}

.AgentScreen .disconnected {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: rgb(196, 64, 77);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.AgentScreen .screen {
  width: 100%;
  height: calc(100% - 80px);
  border-radius: 30px;
  overflow: hidden;
}

.AgentScreen .agent-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.AgentScreen .btn {
  padding: 15px 20px;
  background: rgb(51, 51, 54);
  font-size: 0.85em;
  color: white;
  cursor: pointer;
  border-left: 1px solid rgb(70, 70, 73);
}

.AgentScreen .btn:hover {
  opacity: 0.95;
}

.AgentScreen .btn-left-most {
  border-radius: 10px 0px 0 10px;
  border-left: 0px none;
}

.AgentScreen .btn-right-most {
  border-radius: 0 10px 10px 0;
}

.AgentScreen .btn-selected {
  background: rgb(26, 26, 28);
}

.AgentScreen .btn-end {
  background: rgb(196, 64, 77);
}

.AgentScreen .full-device-on {
  background: rgb(45, 161, 37);
}

.AgentScreen .timer {
  color: white;
  background: rgb(51, 51, 54);
  width: 60px;
  height: 30px;
  border-radius: 20px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}
