.individual_button {
  background: rgb(0, 0, 0, 20%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: 0.3s ease all;
}

.individual_button:hover {
  background: rgba(255, 255, 255, 0.3);
}
.individual_button:hover p {
  font-weight: 700;
}

.individual_input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: 0.2s ease all;
}

.individual_input:focus {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  font-weight: 700;
}

input:focus {
  outline: none;
}
