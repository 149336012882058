.profile-page table {
  table-layout: fixed;
  width: 100%;
}

.profile-container {
  border: 2px solid #3898ec;
  border-radius: 10px;
  width: 85%;
  margin-left: 8%;
  padding: 5%;
  height: 460px;
}

.card {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
}
.card-body {
  flex: 1 1 auto;
  margin: 0;
  padding: 1.5rem 1.5rem;
  position: relative;
}
.card-title {
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.profile-container p {
  font-size: 20px;
  overflow-y: scroll;
}

.profile-container input {
  margin-left: 2%;
}

.profile-container button {
  margin-right: 5%;
  border: none;
  background-color: #fff;
  color: #c56613;
}

.profile-container button:hover {
  color: #f0a767;
}

.settings-label {
  color: #3898ec;
  text-overflow: ellipsis;
}

.settings-image {
  margin-right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
