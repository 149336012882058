.show {
  display: '';
}

.hide {
  display: none;
}

.gallery-images {
  width: 15em;
}

.paperSize {
  max-width: 750px;
}

.table-margin {
  table-layout: fixed;
  width: 100%;
}

.video-container {
  background-color: black;
  align-items: center;
  text-align: center;
  max-height: 600px;
  background-color: black;
}

.vid-style {
  background-color: black;
  width: 100%;
}

.panel-container {
  text-align: center;
  align-items: center;
  max-height: 600px;
  background-color: #f6f6f6;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tour-button {
  font-size: 2em;
  color: white;
  width: 50%;
  border-radius: 25px;
  border: none;
  margin-bottom: 15px;
}

.tour-button:hover {
  background-color: #e8e8e8;
}

.email-table {
  table-layout: fixed;
  width: 95%;
  margin-left: 5%;
}

.email-table input {
  border-width: none;
  width: 50%;
  margin-bottom: 0.5em;
}

.email-table input[type='file'] {
  color: #3898ec;
}
