.permanentButton:hover {
  background-color: white;
}

/* 
.boldOnHover:hover {
  background: #f3f4f6;
} */

.boldOnHover {
  transition: all ease-in-out 0.05s;
  cursor: pointer;
}

.opacityShiftOnHover:hover {
  opacity: 0.8;
}

.ctaTab {
  height: 100%;
  background-color: white;
  color: black;
  font-size: small;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}

.selectedTab {
  border: none;
  border-top: 4px solid var(--primary-color);
}

.dark-on-hover {
  stroke: gray;
}

.dark-on-hover:hover {
  stroke: rgb(52, 51, 51);
}
