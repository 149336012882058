:root,
#root,
html,
body {
  width: 100%;
  height: 100%;
}

.CobrowseWrapper {
  display: flex;
  flex-direction: row;
  text-align: center;
  height: 100%;
  width: 100%;
}

.CobrowseWrapper .customer-view {
  width: 380px;
  height: 600px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.CobrowseWrapper .agent-view {
  flex: 1;
  /* padding: 10px; */
  /* height: 600px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.agent-session {
  width: 100%;
  height: 100%;
}

.screen {
  width: 100%;
}

.primary-btn {
  background-color: #6366f1;
  color: white;
  width: fit-content;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  font-weight: bold;
}

.primary-btn:hover {
  background-color: #4f46e5;
}

.primary-btn:active {
  background-color: #4338ca;
}

.primary-input {
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.primary-input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
