$themeColor: #3898ec;
$themeColorHover: #3898ec;
$pageBackground: #fff;
$lightButtonColor: #ffffff;
$lightButtonBorderColor: #a0a0a0;
$lightButtonColorHover: #f1f1f1;

$white: #fff;
$red: #d80000;
$black: #201f1e;
$lightBlack: #3c4858;
$green: #0bc573;

$grey-1: #605e5c;

$borderColor: #ebf0f5;
$border-color-1: #e1e6ef;
$border-color-2: #c8c5d4;
$color-black-3: #a19f9d;

.color {
  color: $themeColor;
}
.red {
  color: $red;
}
.green {
  color: $green;
}
.black {
  color: $black;
}
.lightBlack {
  color: $lightBlack;
}
