@value mobile from "./media.css";

.progress-container {
  grid-column: 1 / -1;
  grid-row: 1;
  width: 100%;
  border: black 1px;
  z-index: 4000;
}

._progress-bar {
  display: flex;
  flex-basis: 100%;
  height: 10px;
  transition: height 0.3s;
  background-color: var(--button-background-light);
  /* border: 1px solid var(--button-background-white); */
  border-bottom: hidden;
  cursor: pointer;
  overflow: hidden;
}

._progress-bar:hover {
  height: 20px;
}

.progress-filled {
  width: 50%;
  background: var(--primary-color);
  cursor: pointer;
  transition: flex-basis 0.5fs;
}

@media mobile {
  ._progress-bar {
    height: 20px;
  }
}
