@value accentColor: #ff0000;
@value darkPopupColor: #2e3442;
@value darkAccentColor: #e74435;
@value textColor: #ffffff;
@value errorTextColor: #ff4444;
@value progressColor: #e74435;
@value buttonBackground: rgba(0, 0, 0, 0.6);
@value buttonBackgroundHover: rgba(0, 0, 0, 0.85);
@value lightButtonBackground: rgba(0, 0, 0, 0.35);
@value videoBackground: linear-gradient(180deg, transparent 0%, transparent 80%, rgba(0,0,0,1) 98%, rgba(0,0,0,1) 100%);
@value videoBackgroundLandscape: linear-gradient(180deg, transparent 0%, transparent 90%, rgba(0,0,0,1) 98%, rgba(0,0,0,1) 100%);
@value blackShadow: rgba(0, 0, 0, 0.5);
@value blackShadowDark: rgba(0, 0, 0, 0.7);

/* @media breakpoints */
@value maxTabletWidth: 992px;
@value phone: only screen and (max-width: 600px);
@value mobile: only screen and (max-width: maxTabletWidth);
@value landscapeLayout: only screen and (max-width: maxTabletWidth) and (min-aspect-ratio: 3/2);

@value mainFont: Poppins, sans-serif;
/* TODO: Responsive font sizes? */
@value smallFontSize: 14px;
@value mainFontSize: 16px;
@value largeFontSize: 18px;
@value titleFontSize: 28px;

.main-button {
  position: absolute;
  transition: transform 0.1s ease-in-out 0.1s; /* Shrink when hover/focus stops */
  transform: scale(1);
  cursor: pointer;
  z-index: 2147483638;
  box-shadow: blackShadow 0px 4px 20px;
  outline: none;
  border: 8px solid accentColor;
  background-color: accentColor;
}

.main-button:hover,
.main-button:focus,
.main-button:active {
  transition: transform 0.2s ease-in-out 0.1s;
  transform: scale(1.1);
}

.main-button-entering {
  animation: 0.6s ease-out 0s 1 normal none running mainButtonEnter; /* Fade-in/slide up animation */
  animation-direction: backwards;
}

.main-button-entered {
  display: inherit;
  visibility: visible;
}

.main-button-exiting {
  animation: slideOutToLeft 1s;
}

.main-button-exited {
  display: none;
  visibility: hidden;
}

.button-circle {
  width: 112px;
  height: 112px;
  border-radius: 100%;
  padding: 0px;
  background: blackShadow;
}

.button-circle.extra-large-button {
  width: 132px;
  height: 132px;
}

.button-circle.button-bottom {
  bottom: 30px;
}

.button-circle.button-left {
  left: 30px;
}

.button-circle .button-label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  padding: 10%;
  z-index: 2147483647;
  text-shadow: blackShadow 0px 0px 4px;
  font-size: smallFontSize;
  line-height: 18px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: textColor;
  text-transform: initial;
  font-family: mainFont;
  cursor: pointer;
}

.button-circle.extra-large-button .button-label {
  width: 120px;
  height: 120px;
  font-size: mainFontSize;
}

.button-circle .thumb-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: black;
  overflow: hidden;
  border-radius: 100%;
}

.button-circle .thumb,
.thumb-overlay {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  cursor: pointer;
  object-fit: cover;
}

.thumb-overlay {
  background-color: black;
  opacity: 0.5;
  position: absolute;
  top: 0;
  border-radius: 100%;
}

@media phone {
  .button-circle.extra-large-button {
    width: 120px;
    height: 120px;
  }

  .button-circle.extra-large-button .button-label {
    width: 108px;
    height: 108px;
    font-size: smallFontSize;
    text-transform: initial;
  }

  .button-circle.button-bottom {
    bottom: 15px;
  }

  .button-circle.button-left {
    left: 15px;
  }
}
