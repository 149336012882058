.swiper-button {
  display: flex;
  position: absolute;
  top: 50%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  padding: 8px;
}

.image-swiper-button-prev {
  left: 1rem;
  /*The MUIv4 back arrow icon is not centered so these paddings are adjusted*/
  padding-left: 12.11px;
  padding-right: 3.89px;
}

.image-swiper-button-next {
  right: 1rem;
}

.swiper-slide {
  width: auto;
  height: auto;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.full-image-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper-button svg {
  height: 1rem;
  width: 1rem;
}

/* .thumbnail-swiper .swiper-slide {
  opacity: 0.6;
}

.thumbnail-swiper .swiper-slide-thumb-active {
  opacity: 1;
} */
