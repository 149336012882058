/* Desktop styling */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  text-decoration: none;
  opacity: 0.8;
  color: #ffac12;
}

/* Animation */

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 20px;
  background-color: #ffac12;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  color: #000;
  font-weight: 500;
  text-decoration: none;
}

.button .MuiSvgIcon-root {
  margin-left: 5px;
}

.tour-headline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 3%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
  font-size: 16px;
}

.tour-headline-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tour-headline-span {
  margin-right: 10px;
  padding: 4px 10px;
  border-top-right-radius: 12px;
  background-color: #ffac12;
  color: #000;
  font-weight: 500;
}

.tour-headline-text {
  color: white;
  margin-right: 10px;
}

.tour-headline-text a {
  color: white;
}

.tour-container {
  position: relative;
}

.tour-nav {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 90px;
  padding: 20px 4%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  background-color: transparent;
}

.tour-nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1140px;
  min-height: auto;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand {
  margin-right: 20px;
}

.white-fader .button {
  font-size: 18px;
  height: 60px;
}

.display-heading.nav-header {
  margin-bottom: auto;
  font-size: 25px;
}

.display-heading {
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 22px;
  color: #fff;
  font-size: 70px;
  line-height: 0.95;
  letter-spacing: -0.04em;
}

.display-heading h1 {
  font-weight: 700;
}

.display-heading-span {
  font-size: 15px;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #3f0a3d;
  transform: translateY(0px) translateX(0px);
}

.nav-link-plain {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 18px;
  padding-left: 0px;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  color: #fff;
  font-size: 15px;
}

.line-break {
  width: 1px;
  min-height: 25px;
  margin-right: 32px;
  margin-left: 14px;
  background-color: hsla(0, 0%, 86.7%, 0.45);
}

.slider-content {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: block;
}

.custom-share-video {
  position: absolute;
  top: -110%;
  right: 0%;
  z-index: 99;
  display: block;
  max-width: 45%;
  border-radius: 50px;
}

.slide-content-wrap {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 85vh;
  padding: 60px 3%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  /* background-color: black; */
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.65)), to(rgba(20, 20, 20, 0.65))), url("https://ds4.cityrealty.com/img/5cf6a38d4a3c89074f908fd693dfe2a489ebe89c+w+h+0+60/the-puck-penthouses-293-lafayette-street-00.jpg"); */
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65),
      rgba(20, 20, 20, 0.65)
    ),
    url('https://ds4.cityrealty.com/img/5cf6a38d4a3c89074f908fd693dfe2a489ebe89c+w+h+0+60/the-puck-penthouses-293-lafayette-street-00.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, auto;
}

.share-video-container {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.hero-paragraph {
  max-width: 550px;
  color: hsla(0, 0%, 100%, 0.8);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}

.tour-banner-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.tour-banner-flex .button {
  margin-left: 30px;
}

.plain-link-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 34px;
  padding-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #c7c2c2;
}

.hero-block {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
  background-color: #fff;
  color: #000;
  font-size: 20px;
}

.property-section {
  top: 90vh;
  position: relative;
  padding: 0px 4%;
  background-color: inherit;
}

.share-video-container.video-list {
  top: -209px;
  z-index: 100;
}

.property-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.no-videos-found {
  margin-top: 120px;
  color: black;
}

.collection-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.collection-item a:hover {
  opacity: 1;
}

.property-card {
  overflow: hidden;
  width: 25vw;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 18px 18px 45px 0 rgb(0 0 0 / 10%);
  position: relative;
}

.html-embed {
  position: absolute;
  opacity: 0.6;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-transition: opacity 300ms ease, -webkit-transform 300ms ease;
  transition: opacity 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, opacity 300ms ease;
  transition: transform 300ms ease, opacity 300ms ease,
    -webkit-transform 300ms ease;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.html-embed:hover {
  opacity: 0.8;
}

.property-image-wrap {
  overflow: hidden;
  height: 230px;
}

.property-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.property-content {
  padding: 32px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.room-feature-grid {
  grid-column-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-size: 17px;
  font-weight: 700;
}

.feature:hover {
  opacity: 0.7;
}

.header-dark {
  position: relative;
  padding: 152px 4% 60px;
  background-color: #000;
}

.property-title-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.property-detail-text {
  margin-bottom: 10px;
  color: #f5f5f5;
  font-size: 32px;
  line-height: 1;
  font-weight: 700;
}

.property-text-light {
  color: hsla(0, 0%, 100%, 0.58);
}

.property-detail {
  position: relative;
  padding: 70px 3%;
  background-color: #f5f5f5;
}

.grid-3 {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-columns: 2fr 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.staff-card {
  position: -webkit-sticky;
  position: sticky;
  top: 32px;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 23px 23px 34px 0 rgb(0 0 0 / 10%);
}

.avatar-base {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
  padding: 22px 25px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: #f5f5f5;
}

.avatar-wrap {
  overflow: hidden;
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 100px;
}

.avatar-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.agent-name-2-0 {
  font-size: 15px;
  font-weight: 700;
}

.agent-link {
  color: #ffac12;
}

.w-form {
  margin: 0 0 15px;
}

.text-field {
  min-height: 60px;
  margin-bottom: 14px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
}

.text-field-large {
  min-height: 130px;
  margin-bottom: 14px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
}

.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.button-submit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 32px;
  padding: 15px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  border-top-right-radius: 20px;
  background-color: #000;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
}

.property-video {
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
}

.property-video video {
  max-width: 700px;
  max-height: 700px;
}

.gallery-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.collection-list-wrapper {
  width: 100%;
}

.collection-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.collection-item-2 {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 6px;
  width: 23%;
}

.gallery-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 110px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  max-width: 100%;
}

.image-cover {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transform: translate3d(0px, 25px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
}

.view {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 55px;
  height: 55px;
  min-height: 55px;
  min-width: 55px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #ffac12;
}

.image-37 {
  width: 25px;
}

.image-38 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.white-block {
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 23px 23px 34px 0 rgb(0 0 0 / 10%);
  color: black;
  width: 100%;
}

.title-block {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title-block h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 32px;
  line-height: 30px;
  font-weight: 700;
  text-align: left;
}

.content-wrapper {
  padding: 24px;
}

.content-wrapper p {
  margin-bottom: 5px;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
}

.collection-list-3 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.details-listitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 7px;
  padding-bottom: 0px;
}

.filter-icon {
  margin-right: 6px;
}

.all-videos-section {
  padding: 0px 4%;
  background-color: #f5f5f5;
  height: 120vh;
}

.title-line {
  width: 100%;
  max-width: 200px;
  min-height: 4px;
  margin-bottom: 12px;
  border-radius: 20px;
  background-color: #ffac12;
  background-image: none;
}

.all-videos-section h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #000;
  font-size: 44px;
  line-height: 1;
  font-weight: 700;
}

.title-wrap {
  margin-bottom: 10px;
}

.all-videos-section .property-section {
  top: 240px;
  position: relative;
  padding: 0px;
  background-color: #f5f5f5;
}

.all-videos-section .property-list {
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.m-nav-button,
.menu-button-green {
  display: none;
}

@media only screen and (max-width: 991px) {
  .tour-headline,
  .nav-menu {
    display: none;
  }

  .m-nav-button {
    display: block;
    position: relative;
    float: right;
    padding: 10px 20px 10px 20px;
    font-size: 20px;
    display: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .menu-button-green {
    display: block;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  .property-card {
    width: 78vw;
  }

  .grid-3 {
    display: block;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .property-detail {
    display: block;
    width: 100%;
  }

  .property-video video {
    max-width: 100%;
  }

  .collection-item-2 {
    width: 44%;
  }

  .nav-link-plain {
    color: black;
    width: 100%;
  }

  hr {
    margin: 0;
  }

  .collection-list {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 600px) {
  .display-heading {
    font-size: 50px;
  }

  .tour-banner-flex {
    display: block;
  }

  .tour-banner-flex .button {
    margin-left: 0px;
  }

  .white-fader .button {
    width: 220px;
  }

  .property-title-wrap {
    display: block;
  }

  .header-content-left {
    margin-bottom: 10px;
  }

  .header-dark {
    padding: 110px 2% 30px;
  }

  .share-video-container.video-list {
    top: -170px;
  }

  .collection-list-3 {
    display: block;
  }

  .all-videos-section h2 {
    font-size: 32px;
    margin-bottom: 0px;
  }

  .gallery-item {
    height: 60px;
  }
}
