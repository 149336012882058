.verticalDivider {
  width: 0;
  margin: 0 2rem;
  overflow: hidden;
  border-right: 1px solid #e9ecef;
  align-self: stretch;
}

.magnetSummaryRowContainer {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.screensContainer {
  display: flex;
  flex-wrap: wrap;
}

.magnetScreenItemContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 15px;
  border-radius: 14px;
  width: 120px;
  margin: 0 10px;
  box-sizing: content-box;
  background-color: white;
}

.screenFigCaption {
  margin: 10px 0 5px;
  width: 100%;
  display: block;
  word-wrap: break-word;
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
}

.magnetScreenItemContainer:hover {
  transform: scale(1.05);
  background-color: #eef1f2;
}

.shareScreenCheckbox {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.addButton {
  margin-top: 20px;
  height: 70px;
  width: 120px;
  border-radius: 25px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.fixedStatusBar {
  height: 200;
  width: 100%;
  position: sticky;
  left: 0;
  bottom: 0;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 15px;
}

.magnetSummaryRowEdit {
  padding-top: 30px;
  padding-bottom: 60px;
  margin-bottom: 20px;
  border-bottom: 2px solid #efefef;
}

.magnetSummaryRowEdit .cancelBtn {
  color: grey;
}

.magnetSummaryRowEdit .saveBtn {
  margin-right: 15px;
}

.magnetSummaryRowEdit .deleteBtn {
  color: #3898ec;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3898ec;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  animation: spin 1s linear infinite;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.loadingText {
  position: absolute;
  text-align: center;
}

.newVideoDialogWrapper {
  padding: 16px;
  position: relative;
}

.newVideoPopup {
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.newVideoPopupNextButtonWrapper {
  display: flex;
}
