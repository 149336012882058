@value mobile,
landscapeLayout from "./media.css";

.video-controls {
  grid-column: 1 / -1;
  grid-row: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* width: 100%;
  margin: 2% 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 5%; */
  /*height: 0;  Hacky decison for iframe embed accessing buttons*/
  /* For absolutely positioning ExpandedVideoSettings */
  position: relative;
}

.top-row-controls {
  width: 100%;
  margin: 2% 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 5%;
  margin-bottom: 5px;
}

.video-control {
  border-radius: 8px;
  /* `gap` doesn't seem to work in Safari */
  margin-right: 2%;
}

/* Only selects control icons (not the expanded video settings) */
.video-controls button.video-control {
  height: 35px;
  width: 35px;
  position: relative;
  /* For absolutely-positioned child SVGs */
  background-color: var(--button-border-light);
  border: 1px solid var(--button-background-white);
  transition: background-color 0.5s, border 0.5s;
}

.video-controls button.video-control:hover {
  background-color: var(--button-background-dark);
  border: 1px solid var(--primary-color);
}

.video-timestamp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--text-color);
  font-size: var(--small-font-size);
  font-weight: 700;
  padding: 5px;
  text-shadow: 1px 1px 4px black;
  border-radius: 8px;
}

.controls-spacer {
  flex-grow: 1;
}

.settings-expanded {
  top: 100%;
  right: 0;
  width: min(300px, max(200px, 45%));
  background-color: var(--button-background-dark);
  margin: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.settings-expanded-label {
  cursor: pointer;
  background-color: var(--button-background-light);
  border-radius: 8px;
  width: 100%;
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  font-size: var(--small-font-size);
  margin: 5px 0;
}

.video-controls .accent-text {
  color: var(--button-background-light);
  cursor: pointer;
  font-weight: bold;
}

.video-controls .icon-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}

.icon-text {
  color: white;
}

.video-controls .icon-text svg {
  fill: white;
}

@media landscapeLayout {
  .video-controls {
    margin-top: 1%;
  }
}

.location-chip {
  margin: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.276);
  border-radius: 500px;
  /* border: 2px solid rgba(128, 128, 128, 0.194); */
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: -8px;
  text-shadow: none;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
  margin-right: auto;
}
