.middlesharingimage {
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
  border-radius: 4px;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.2);
}

.sidesharingimage {
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
  border-radius: 4px;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
  opacity: 0.75;
}
