@value mobile from "./media.css";

.contact-card-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--button-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
}

.contact-info-main {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* background-image: url(https://img.freepik.com/free-vector/marble-background-rose-gold_25819-674.jpg?size=626&ext=jpg); */
  border-radius: 10px;
  padding: 0 100px 50px;
  margin-top: 150px;
}

.contact-card-profile-picture {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 25px;
  border: 5px solid var(--button-background);
  margin-top: -55px;
  margin-bottom: 20px;
}

.contact-card-name {
  font-size: var(--title-font-size);
  font-weight: bold;
  color: black;
  margin-bottom: 15px;
}

.contact-card-company {
  font-size: var(--main-font-size);
  color: black;
}

.contact-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

.contact-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  gap: 5px;
  color: black;
  text-decoration: underline;
}

.contact-card-download-button {
  box-shadow: none;
  border: none;
  background: var(--primary-color);
  font-size: var(--small-font-size);
  padding: 10px;
  width: 100%;
  color: var(--text-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  border-radius: 10px;
  gap: 5px;
  cursor: pointer;
}

.contact-card-download-button svg {
  fill: var(--text-color);
}

.contact-card-divider {
  width: 100%;
  margin: 20px 0;
}

@media mobile {
  .contact-info-main {
    padding: 0 20px 50px;
  }
}
