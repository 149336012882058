.share-container {
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background: var(--button-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.share-title {
  font-size: var(--title-font-size);
  line-height: 1.15em;
}

.share-link {
  font-size: var(--large-font-size);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
  padding: 20px;
  background-color: var(--button-background-light);
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin: 20px 0 30px;
  transition: border 300ms;
  cursor: pointer;
}

.share-link:hover {
  border-color: var(--primary-color);
}

.share-link-default,
.share-link-custom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.share-link-text {
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--main-font-size);
}

.share-link-caption {
  font-size: var(--small-font-size);
}

.share-link svg {
  flex: none;
}

.share-link svg[data-accent='true'] {
  fill: var(--primary-color);
}

.vertical-divider {
  border-left: 1px solid white;
  height: 100%;
}

/* Center Button Page */

.center-button-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  padding: 22px;
  padding-top: 75px;
}

.center-button-page .title {
  font-size: 22px;
  font-weight: 600;
  margin-top: 40px;
}

.center-button-page .tag {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.center-button-page .tag .icon {
  margin-right: 5px;
  margin-top: 3px;
}

.center-button-page .buttons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-right: 10px;
  border-radius: 10px;
  overflow: hidden;
  overflow-y: auto;
}

.center-button-page .buttons-grid .button-blk {
  width: 100%;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
}

.center-button-page .buttons-grid .button-blk .poster {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: relative;
  transition: all 0.15s linear;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px) !important;
}

.center-button-page .buttons-grid .button-blk:hover .poster {
  background-color: transparent;
  transform: scale(1.03);
}

.center-button-page .buttons-grid .button-blk:hover .overlay {
  background-color: rgba(0, 0, 0, 0.3);
}

.center-button-page .buttons-grid .button-blk .overlay {
  position: absolute;
  inset: 0;
  z-index: 3;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 16px;
}

.center-button-page .buttons-grid .button-blk .overlay .video-title {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.center-button-page .buttons-grid .button-blk .play-btn,
.link-btn {
  /* background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px); */
  /* border: 2px solid #fff; */
  /* height: 120px;
  width: 120px;
  border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: all 0.15s linear;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}

.center-button-page .buttons-grid .button-blk .play-btn {
  left: 12px;
  top: 12px;
}

.center-button-page .buttons-grid .button-blk .link-btn {
  left: 20px;
  top: 15px;
}

.center-button-page .buttons-grid .button-blk:hover .play-btn {
  visibility: visible;
  opacity: 1;
}
.center-button-page .buttons-grid .button-blk .play-btn svg {
  height: 30px;
  width: 30px;
}

.center-button-page .buttons-grid .button-blk:hover .link-btn {
  visibility: visible;
  opacity: 1;
}

@keyframes fadeInUpCenter {
  from {
    /* Using 150% instead of 100% so that the element is completely offscreen before disappearing */
    transform: translateY(150%);
    opacity: 0;
    visibility: hidden;
  }
  to {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.fade-in-up-center {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
