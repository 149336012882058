.backer-pill {
  border: 1px solid #dad4d7;
  padding: 6px 12px;
  color: #74707b;
  font-size: 12px;
}

.email-input {
  color: #74707b;
  border: 2px solid #cbcad6;
}

.get-started-button {
  border-radius: 10px;
  background: linear-gradient(99.74deg, #4d8ae6 52.83%, #8daddd 105.26%);
  padding: 12px 26px;
  color: white;
}

.image-container img {
  filter: drop-shadow(0px 15.7085px 62.8339px rgba(17, 59, 122, 0.38));
  transition: all 0.6s ease-in-out;
}

.image-container img:hover {
  transform: scale(1.1);
}

#tour-startbox {
  animation-name: fade-slide-left;
  animation-duration: 3.5s;
  width: 28vw;
}

#calendar-container {
  animation-name: fade-slide-right;
  animation-duration: 4s;
  height: 25vw;
}

#new-tour-card {
  animation-name: fade-slide-right;
  animation-duration: 3.8s;
  height: 22vw;
}

#floating-notif {
  animation-name: fade-slide-down;
  animation-duration: 4.2s;
  height: 10vw;
}

#magnet-circle {
  height: 13vw;
}

@keyframes fade-slide-left {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  57% {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fade-slide-right {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  60% {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fade-slide-down {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  80% {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
