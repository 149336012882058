.share-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Copied from the icon-scale-transform and hover-outline classes to fix one `transition` overwriting the other */
/* TODO: Can we remove this and just use `.transition-all`? */
.share-buttons > button {
  padding: 0;
  margin: 0 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  transform: scale(1);
  transition: box-shadow 225ms, transform 225ms;
}

.share-buttons button:hover {
  border: 2px solid var(--primary-color) !important;
  transform: scale(1.2);
}

.share-buttons svg {
  width: auto;
  height: 100%;
  max-height: 5vh;
  display: block;
}
