@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?2dtxv0');
  src: url('fonts/icomoon.eot?2dtxv0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?2dtxv0') format('truetype'),
    url('fonts/icomoon.woff?2dtxv0') format('woff'),
    url('fonts/icomoon.svg?2dtxv0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_drop_up:before {
  content: '\ef10';
}
.icon-arrow_drop_down:before {
  content: '\ef11';
}
.icon-delete1:before {
  content: '\ef08';
}
.icon-create:before {
  content: '\e900';
}
.icon-home:before {
  content: '\e901';
}
.icon-home2:before {
  content: '\e902';
}
.icon-home3:before {
  content: '\e903';
}
.icon-home4:before {
  content: '\e904';
}
.icon-home-lock:before {
  content: '\e905';
}
.icon-home-sleep:before {
  content: '\e906';
}
.icon-home-smart:before {
  content: '\e907';
}
.icon-home-user:before {
  content: '\e908';
}
.icon-faucet:before {
  content: '\e909';
}
.icon-sink:before {
  content: '\e90a';
}
.icon-plunger:before {
  content: '\e90b';
}
.icon-bathtub:before {
  content: '\e90c';
}
.icon-rubber-duck:before {
  content: '\e90d';
}
.icon-towel:before {
  content: '\e90e';
}
.icon-toothbrush:before {
  content: '\e90f';
}
.icon-razor:before {
  content: '\e910';
}
.icon-bed:before {
  content: '\e911';
}
.icon-bed-time:before {
  content: '\e912';
}
.icon-bed-check:before {
  content: '\e913';
}
.icon-bed-cross:before {
  content: '\e914';
}
.icon-couch:before {
  content: '\e915';
}
.icon-chair:before {
  content: '\e916';
}
.icon-office:before {
  content: '\e917';
}
.icon-city:before {
  content: '\e918';
}
.icon-pencil:before {
  content: '\e919';
}
.icon-pencil2:before {
  content: '\e91a';
}
.icon-pen:before {
  content: '\e91b';
}
.icon-pencil3:before {
  content: '\e91c';
}
.icon-eraser:before {
  content: '\e91d';
}
.icon-edit:before {
  content: '\e91e';
}
.icon-edit2:before {
  content: '\e91f';
}
.icon-notebook:before {
  content: '\e920';
}
.icon-quill:before {
  content: '\e921';
}
.icon-edit3:before {
  content: '\e922';
}
.icon-blog:before {
  content: '\e923';
}
.icon-pen2:before {
  content: '\e924';
}
.icon-pen-plus:before {
  content: '\e925';
}
.icon-pen-minus:before {
  content: '\e926';
}
.icon-pen-drawing:before {
  content: '\e927';
}
.icon-bezier-curve:before {
  content: '\e928';
}
.icon-bezier-curve2:before {
  content: '\e929';
}
.icon-brush:before {
  content: '\e92a';
}
.icon-brush2:before {
  content: '\e92b';
}
.icon-paint-roller:before {
  content: '\e92c';
}
.icon-spray:before {
  content: '\e92d';
}
.icon-spray-cross:before {
  content: '\e92e';
}
.icon-spray-bug:before {
  content: '\e92f';
}
.icon-spray2:before {
  content: '\e930';
}
.icon-window:before {
  content: '\e931';
}
.icon-tape-measure:before {
  content: '\e932';
}
.icon-tape:before {
  content: '\e933';
}
.icon-tape-dispenser:before {
  content: '\e934';
}
.icon-stamp:before {
  content: '\e935';
}
.icon-texture:before {
  content: '\e936';
}
.icon-tile:before {
  content: '\e937';
}
.icon-eyedropper:before {
  content: '\e938';
}
.icon-palette:before {
  content: '\e939';
}
.icon-color-sampler:before {
  content: '\e93a';
}
.icon-bucket:before {
  content: '\e93b';
}
.icon-sticker:before {
  content: '\e93c';
}
.icon-gradient:before {
  content: '\e93d';
}
.icon-magic-wand:before {
  content: '\e93e';
}
.icon-magnet:before {
  content: '\e93f';
}
.icon-magnet-cross:before {
  content: '\e940';
}
.icon-magnet-grid:before {
  content: '\e941';
}
.icon-magnet-point:before {
  content: '\e942';
}
.icon-magnet-angle:before {
  content: '\e943';
}
.icon-magnet-scale:before {
  content: '\e944';
}
.icon-pencil-ruler:before {
  content: '\e945';
}
.icon-pencil-ruler2:before {
  content: '\e946';
}
.icon-compass:before {
  content: '\e947';
}
.icon-polygon:before {
  content: '\e948';
}
.icon-polygon2:before {
  content: '\e949';
}
.icon-polygon3:before {
  content: '\e94a';
}
.icon-sword:before {
  content: '\e94b';
}
.icon-gun:before {
  content: '\e94c';
}
.icon-aim:before {
  content: '\e94d';
}
.icon-grenade:before {
  content: '\e94e';
}
.icon-bomb:before {
  content: '\e94f';
}
.icon-explosion:before {
  content: '\e950';
}
.icon-nuclear:before {
  content: '\e951';
}
.icon-bottle:before {
  content: '\e952';
}
.icon-drop:before {
  content: '\e953';
}
.icon-droplet-plus:before {
  content: '\e954';
}
.icon-droplet-minus:before {
  content: '\e955';
}
.icon-droplet-cross:before {
  content: '\e956';
}
.icon-oil-price:before {
  content: '\e957';
}
.icon-coolant:before {
  content: '\e958';
}
.icon-snowflake:before {
  content: '\e959';
}
.icon-snowflake2:before {
  content: '\e95a';
}
.icon-fire:before {
  content: '\e95b';
}
.icon-fire-forbidden:before {
  content: '\e95c';
}
.icon-fire-price:before {
  content: '\e95d';
}
.icon-fire-warning:before {
  content: '\e95e';
}
.icon-fire-hazard:before {
  content: '\e95f';
}
.icon-fireworks:before {
  content: '\e960';
}
.icon-match-fire:before {
  content: '\e961';
}
.icon-hydrant:before {
  content: '\e962';
}
.icon-extinguisher:before {
  content: '\e963';
}
.icon-lighter:before {
  content: '\e964';
}
.icon-pocket-knife:before {
  content: '\e965';
}
.icon-tissue:before {
  content: '\e966';
}
.icon-toilet-paper:before {
  content: '\e967';
}
.icon-paper-towel:before {
  content: '\e968';
}
.icon-poop:before {
  content: '\e969';
}
.icon-umbrella:before {
  content: '\e96a';
}
.icon-umbrella-rain:before {
  content: '\e96b';
}
.icon-droplets:before {
  content: '\e96c';
}
.icon-power:before {
  content: '\e96d';
}
.icon-volcano:before {
  content: '\e96e';
}
.icon-gas-mask:before {
  content: '\e96f';
}
.icon-tornado:before {
  content: '\e970';
}
.icon-wind:before {
  content: '\e971';
}
.icon-fan:before {
  content: '\e972';
}
.icon-fan2:before {
  content: '\e973';
}
.icon-fan3:before {
  content: '\e974';
}
.icon-contrast:before {
  content: '\e975';
}
.icon-brightness:before {
  content: '\e976';
}
.icon-brightness-auto:before {
  content: '\e977';
}
.icon-brightness-auto2:before {
  content: '\e978';
}
.icon-brightness-contrast:before {
  content: '\e979';
}
.icon-sun:before {
  content: '\e97a';
}
.icon-moon:before {
  content: '\e97b';
}
.icon-cloud:before {
  content: '\e97c';
}
.icon-cloud-rain:before {
  content: '\e97d';
}
.icon-cloud-rain2:before {
  content: '\e97e';
}
.icon-cloud-rain3:before {
  content: '\e97f';
}
.icon-cloud-snow:before {
  content: '\e980';
}
.icon-cloud-snow2:before {
  content: '\e981';
}
.icon-sun-wind:before {
  content: '\e982';
}
.icon-cloud-wind:before {
  content: '\e983';
}
.icon-sun-fog:before {
  content: '\e984';
}
.icon-cloud-fog:before {
  content: '\e985';
}
.icon-cloud-sun:before {
  content: '\e986';
}
.icon-cloud-bolt:before {
  content: '\e987';
}
.icon-cloud-upload:before {
  content: '\e988';
}
.icon-cloud-download:before {
  content: '\e989';
}
.icon-cloud-sync:before {
  content: '\e98a';
}
.icon-cloud-backup:before {
  content: '\e98b';
}
.icon-cloud-lock:before {
  content: '\e98c';
}
.icon-cloud-settings:before {
  content: '\e98d';
}
.icon-cloud-warning:before {
  content: '\e98e';
}
.icon-cloud-time:before {
  content: '\e98f';
}
.icon-cloud-check:before {
  content: '\e990';
}
.icon-cloud-cross:before {
  content: '\e991';
}
.icon-cloud-connected:before {
  content: '\e992';
}
.icon-cloud-database:before {
  content: '\e993';
}
.icon-cloud-music:before {
  content: '\e994';
}
.icon-cloud-music2:before {
  content: '\e995';
}
.icon-cloud-message:before {
  content: '\e996';
}
.icon-branches:before {
  content: '\e997';
}
.icon-database:before {
  content: '\e998';
}
.icon-database-plus:before {
  content: '\e999';
}
.icon-database-minus:before {
  content: '\e99a';
}
.icon-database-lock:before {
  content: '\e99b';
}
.icon-database-sync:before {
  content: '\e99c';
}
.icon-database-backup:before {
  content: '\e99d';
}
.icon-database-check:before {
  content: '\e99e';
}
.icon-database-time:before {
  content: '\e99f';
}
.icon-database-upload:before {
  content: '\e9a0';
}
.icon-database-download:before {
  content: '\e9a1';
}
.icon-shield:before {
  content: '\e9a2';
}
.icon-shield-plus:before {
  content: '\e9a3';
}
.icon-shield-warning:before {
  content: '\e9a4';
}
.icon-shield-check:before {
  content: '\e9a5';
}
.icon-shield-cross:before {
  content: '\e9a6';
}
.icon-shield-sync:before {
  content: '\e9a7';
}
.icon-shield-info:before {
  content: '\e9a8';
}
.icon-shield-web:before {
  content: '\e9a9';
}
.icon-shield-user:before {
  content: '\e9aa';
}
.icon-shield-bolt:before {
  content: '\e9ab';
}
.icon-shield-droplet:before {
  content: '\e9ac';
}
.icon-shield-star:before {
  content: '\e9ad';
}
.icon-refresh-locked:before {
  content: '\e9ae';
}
.icon-lock:before {
  content: '\e9af';
}
.icon-unlock:before {
  content: '\e9b0';
}
.icon-unlock2:before {
  content: '\e9b1';
}
.icon-lock-check:before {
  content: '\e9b2';
}
.icon-lock-cross:before {
  content: '\e9b3';
}
.icon-lock-warning:before {
  content: '\e9b4';
}
.icon-lock2:before {
  content: '\e9b5';
}
.icon-unlock3:before {
  content: '\e9b6';
}
.icon-unlock4:before {
  content: '\e9b7';
}
.icon-lock-wireless:before {
  content: '\e9b8';
}
.icon-key:before {
  content: '\e9b9';
}
.icon-key2:before {
  content: '\e9ba';
}
.icon-key-hole:before {
  content: '\e9bb';
}
.icon-toggle-off:before {
  content: '\e9bc';
}
.icon-toggle-on:before {
  content: '\e9bd';
}
.icon-toggle-on2:before {
  content: '\e9be';
}
.icon-toggle-off2:before {
  content: '\e9bf';
}
.icon-switch-off:before {
  content: '\e9c0';
}
.icon-switch-on:before {
  content: '\e9c1';
}
.icon-equalizer:before {
  content: '\e9c2';
}
.icon-settings-icons:before {
  content: '\e9c3';
}
.icon-gear:before {
  content: '\e9c4';
}
.icon-gear2:before {
  content: '\e9c5';
}
.icon-gear3:before {
  content: '\e9c6';
}
.icon-wrench:before {
  content: '\e9c7';
}
.icon-wrench2:before {
  content: '\e9c8';
}
.icon-screwdriver:before {
  content: '\e9c9';
}
.icon-hammer:before {
  content: '\e9ca';
}
.icon-anvil:before {
  content: '\e9cb';
}
.icon-hammer-wrench:before {
  content: '\e9cc';
}
.icon-wrench-screwdriver:before {
  content: '\e9cd';
}
.icon-saw:before {
  content: '\e9ce';
}
.icon-axe:before {
  content: '\e9cf';
}
.icon-axe2:before {
  content: '\e9d0';
}
.icon-trowel:before {
  content: '\e9d1';
}
.icon-shovel:before {
  content: '\e9d2';
}
.icon-pickaxe:before {
  content: '\e9d3';
}
.icon-factory:before {
  content: '\e9d4';
}
.icon-robot-arm:before {
  content: '\e9d5';
}
.icon-conveyor:before {
  content: '\e9d6';
}
.icon-recycle:before {
  content: '\e9d7';
}
.icon-recycle2:before {
  content: '\e9d8';
}
.icon-trash:before {
  content: '\e9d9';
}
.icon-trash-cross:before {
  content: '\e9da';
}
.icon-trash-full:before {
  content: '\e9db';
}
.icon-trash-warning:before {
  content: '\e9dc';
}
.icon-trash-cross2:before {
  content: '\e9dd';
}
.icon-disposal:before {
  content: '\e9de';
}
.icon-game:before {
  content: '\e9df';
}
.icon-gamepad:before {
  content: '\e9e0';
}
.icon-dice:before {
  content: '\e9e1';
}
.icon-spades:before {
  content: '\e9e2';
}
.icon-spades2:before {
  content: '\e9e3';
}
.icon-diamonds:before {
  content: '\e9e4';
}
.icon-diamonds2:before {
  content: '\e9e5';
}
.icon-clubs:before {
  content: '\e9e6';
}
.icon-clubs2:before {
  content: '\e9e7';
}
.icon-heart:before {
  content: '\e9e8';
}
.icon-heart-shiny:before {
  content: '\e9e9';
}
.icon-star:before {
  content: '\e9ea';
}
.icon-star-half:before {
  content: '\e9eb';
}
.icon-star-empty:before {
  content: '\e9ec';
}
.icon-flag:before {
  content: '\e9ed';
}
.icon-flag2:before {
  content: '\e9ee';
}
.icon-flag3:before {
  content: '\e9ef';
}
.icon-mailbox-empty:before {
  content: '\e9f0';
}
.icon-mailbox-full:before {
  content: '\e9f1';
}
.icon-at-sign:before {
  content: '\e9f2';
}
.icon-envelope:before {
  content: '\e9f3';
}
.icon-envelope-open:before {
  content: '\e9f4';
}
.icon-envelope-open2:before {
  content: '\e9f5';
}
.icon-envelope-plus:before {
  content: '\e9f6';
}
.icon-envelope-warning:before {
  content: '\e9f7';
}
.icon-envelope-check:before {
  content: '\e9f8';
}
.icon-envelope-cross:before {
  content: '\e9f9';
}
.icon-paper-plane:before {
  content: '\e9fa';
}
.icon-paperclip:before {
  content: '\e9fb';
}
.icon-reply:before {
  content: '\e9fc';
}
.icon-reply-all:before {
  content: '\e9fd';
}
.icon-drawer:before {
  content: '\e9fe';
}
.icon-drawer-download:before {
  content: '\e9ff';
}
.icon-drawer-upload:before {
  content: '\ea00';
}
.icon-drawer-full:before {
  content: '\ea01';
}
.icon-drawer-paper:before {
  content: '\ea02';
}
.icon-drawer-warning:before {
  content: '\ea03';
}
.icon-drawer-sync:before {
  content: '\ea04';
}
.icon-box:before {
  content: '\ea05';
}
.icon-drawer-full2:before {
  content: '\ea06';
}
.icon-drawer-paper2:before {
  content: '\ea07';
}
.icon-dresser:before {
  content: '\ea08';
}
.icon-dresser2:before {
  content: '\ea09';
}
.icon-cabinet:before {
  content: '\ea0a';
}
.icon-eye:before {
  content: '\ea0b';
}
.icon-eye-cross:before {
  content: '\ea0c';
}
.icon-eye-plus:before {
  content: '\ea0d';
}
.icon-eye-minus:before {
  content: '\ea0e';
}
.icon-eye-droplet:before {
  content: '\ea0f';
}
.icon-eye2:before {
  content: '\ea10';
}
.icon-binoculars:before {
  content: '\ea11';
}
.icon-binoculars2:before {
  content: '\ea12';
}
.icon-telescope:before {
  content: '\ea13';
}
.icon-server:before {
  content: '\ea14';
}
.icon-install:before {
  content: '\ea15';
}
.icon-upload:before {
  content: '\ea16';
}
.icon-hard-disk:before {
  content: '\ea17';
}
.icon-hard-disk-warning:before {
  content: '\ea18';
}
.icon-floppy-disk:before {
  content: '\ea19';
}
.icon-disc:before {
  content: '\ea1a';
}
.icon-disc-audio:before {
  content: '\ea1b';
}
.icon-disc-play:before {
  content: '\ea1c';
}
.icon-cassette:before {
  content: '\ea1d';
}
.icon-printer:before {
  content: '\ea1e';
}
.icon-plotter:before {
  content: '\ea1f';
}
.icon-printer-3d:before {
  content: '\ea20';
}
.icon-shredder:before {
  content: '\ea21';
}
.icon-file-empty:before {
  content: '\ea22';
}
.icon-file-text:before {
  content: '\ea23';
}
.icon-file-plus:before {
  content: '\ea24';
}
.icon-file-check:before {
  content: '\ea25';
}
.icon-file-lock:before {
  content: '\ea26';
}
.icon-file-user:before {
  content: '\ea27';
}
.icon-file-pencil:before {
  content: '\ea28';
}
.icon-files:before {
  content: '\ea29';
}
.icon-files2:before {
  content: '\ea2a';
}
.icon-files-compare:before {
  content: '\ea2b';
}
.icon-folder:before {
  content: '\ea2c';
}
.icon-folder-history:before {
  content: '\ea2d';
}
.icon-folder-search:before {
  content: '\ea2e';
}
.icon-folder-home:before {
  content: '\ea2f';
}
.icon-folder-plus:before {
  content: '\ea30';
}
.icon-folder-minus:before {
  content: '\ea31';
}
.icon-folder-zip:before {
  content: '\ea32';
}
.icon-folder-lock:before {
  content: '\ea33';
}
.icon-folder-download:before {
  content: '\ea34';
}
.icon-folder-upload:before {
  content: '\ea35';
}
.icon-folder-star:before {
  content: '\ea36';
}
.icon-folder-heart:before {
  content: '\ea37';
}
.icon-folder-user:before {
  content: '\ea38';
}
.icon-folder-share:before {
  content: '\ea39';
}
.icon-folder-code:before {
  content: '\ea3a';
}
.icon-folder-picture:before {
  content: '\ea3b';
}
.icon-folder-camera:before {
  content: '\ea3c';
}
.icon-folder-music:before {
  content: '\ea3d';
}
.icon-folder-music2:before {
  content: '\ea3e';
}
.icon-folder-film:before {
  content: '\ea3f';
}
.icon-folder-video:before {
  content: '\ea40';
}
.icon-scissors:before {
  content: '\ea41';
}
.icon-clipboard-empty:before {
  content: '\ea42';
}
.icon-clipboard-text:before {
  content: '\ea43';
}
.icon-paste:before {
  content: '\ea44';
}
.icon-paste2:before {
  content: '\ea45';
}
.icon-clipboard-check:before {
  content: '\ea46';
}
.icon-clipboard-down:before {
  content: '\ea47';
}
.icon-clipboard-left:before {
  content: '\ea48';
}
.icon-clipboard-warning:before {
  content: '\ea49';
}
.icon-clipboard-user:before {
  content: '\ea4a';
}
.icon-clipboard-pencil:before {
  content: '\ea4b';
}
.icon-checklist:before {
  content: '\ea4c';
}
.icon-clipboard-list:before {
  content: '\ea4d';
}
.icon-clipboard-chart:before {
  content: '\ea4e';
}
.icon-register:before {
  content: '\ea4f';
}
.icon-enter:before {
  content: '\ea50';
}
.icon-exit:before {
  content: '\ea51';
}
.icon-news:before {
  content: '\ea52';
}
.icon-read:before {
  content: '\ea53';
}
.icon-newsletter:before {
  content: '\ea54';
}
.icon-newsletters:before {
  content: '\ea55';
}
.icon-paper:before {
  content: '\ea56';
}
.icon-graduation:before {
  content: '\ea57';
}
.icon-certificate:before {
  content: '\ea58';
}
.icon-certificate2:before {
  content: '\ea59';
}
.icon-medal-empty:before {
  content: '\ea5a';
}
.icon-medal-first:before {
  content: '\ea5b';
}
.icon-medal-second:before {
  content: '\ea5c';
}
.icon-medal-third:before {
  content: '\ea5d';
}
.icon-podium:before {
  content: '\ea5e';
}
.icon-trophy:before {
  content: '\ea5f';
}
.icon-trophy-first:before {
  content: '\ea60';
}
.icon-trophy-second:before {
  content: '\ea61';
}
.icon-trophy-third:before {
  content: '\ea62';
}
.icon-music-note:before {
  content: '\ea63';
}
.icon-music-note2:before {
  content: '\ea64';
}
.icon-music-note-plus:before {
  content: '\ea65';
}
.icon-music-note3:before {
  content: '\ea66';
}
.icon-music-note4:before {
  content: '\ea67';
}
.icon-music-note5:before {
  content: '\ea68';
}
.icon-music-note6:before {
  content: '\ea69';
}
.icon-playlist:before {
  content: '\ea6a';
}
.icon-list-heart:before {
  content: '\ea6b';
}
.icon-cable:before {
  content: '\ea6c';
}
.icon-turntable:before {
  content: '\ea6d';
}
.icon-piano:before {
  content: '\ea6e';
}
.icon-guitar:before {
  content: '\ea6f';
}
.icon-guitar-electric:before {
  content: '\ea70';
}
.icon-guitar-pick:before {
  content: '\ea71';
}
.icon-metronome:before {
  content: '\ea72';
}
.icon-tuning-fork:before {
  content: '\ea73';
}
.icon-album1:before {
  content: '\ea74';
}
.icon-playlist2:before {
  content: '\ea75';
}
.icon-photo-album:before {
  content: '\ea76';
}
.icon-shuffle:before {
  content: '\ea77';
}
.icon-repeat:before {
  content: '\ea78';
}
.icon-repeat-infinite:before {
  content: '\ea79';
}
.icon-repeat-once:before {
  content: '\ea7a';
}
.icon-earphones:before {
  content: '\ea7b';
}
.icon-headphones:before {
  content: '\ea7c';
}
.icon-headset:before {
  content: '\ea7d';
}
.icon-headphones-warning:before {
  content: '\ea7e';
}
.icon-headphones-cross:before {
  content: '\ea7f';
}
.icon-loudspeaker:before {
  content: '\ea80';
}
.icon-equalizer2:before {
  content: '\ea81';
}
.icon-theater:before {
  content: '\ea82';
}
.icon-3d-glasses:before {
  content: '\ea83';
}
.icon-ticket:before {
  content: '\ea84';
}
.icon-megaphone:before {
  content: '\ea85';
}
.icon-clapboard:before {
  content: '\ea86';
}
.icon-clapboard-play:before {
  content: '\ea87';
}
.icon-reel:before {
  content: '\ea88';
}
.icon-film:before {
  content: '\ea89';
}
.icon-film-play:before {
  content: '\ea8a';
}
.icon-film-play2:before {
  content: '\ea8b';
}
.icon-projector-screen-collapsed:before {
  content: '\ea8c';
}
.icon-projector-screen-play:before {
  content: '\ea8d';
}
.icon-projector-screen-chart:before {
  content: '\ea8e';
}
.icon-surveillance:before {
  content: '\ea8f';
}
.icon-surveillance2:before {
  content: '\ea90';
}
.icon-camera:before {
  content: '\ea91';
}
.icon-camera-cross:before {
  content: '\ea92';
}
.icon-camera-play:before {
  content: '\ea93';
}
.icon-camera-pause:before {
  content: '\ea94';
}
.icon-camera-stop:before {
  content: '\ea95';
}
.icon-camera-record:before {
  content: '\ea96';
}
.icon-camera-time:before {
  content: '\ea97';
}
.icon-camera2:before {
  content: '\ea98';
}
.icon-camera3:before {
  content: '\ea99';
}
.icon-camera-time2:before {
  content: '\ea9a';
}
.icon-camera-flip:before {
  content: '\ea9b';
}
.icon-camera-stabilization:before {
  content: '\ea9c';
}
.icon-panorama:before {
  content: '\ea9d';
}
.icon-shutter:before {
  content: '\ea9e';
}
.icon-face-detection:before {
  content: '\ea9f';
}
.icon-convex:before {
  content: '\eaa0';
}
.icon-concave:before {
  content: '\eaa1';
}
.icon-picture:before {
  content: '\eaa2';
}
.icon-picture2:before {
  content: '\eaa3';
}
.icon-book1:before {
  content: '\eaa4';
}
.icon-book-audio:before {
  content: '\eaa5';
}
.icon-book2:before {
  content: '\eaa6';
}
.icon-book3:before {
  content: '\eaa7';
}
.icon-book-bookmarked:before {
  content: '\eaa8';
}
.icon-book-bookmarked2:before {
  content: '\eaa9';
}
.icon-bookmark1:before {
  content: '\eaaa';
}
.icon-bookmark-move:before {
  content: '\eaab';
}
.icon-bookmark-plus:before {
  content: '\eaac';
}
.icon-bookmark-minus:before {
  content: '\eaad';
}
.icon-label:before {
  content: '\eaae';
}
.icon-books1:before {
  content: '\eaaf';
}
.icon-library:before {
  content: '\eab0';
}
.icon-contacts:before {
  content: '\eab1';
}
.icon-business-card:before {
  content: '\eab2';
}
.icon-portrait:before {
  content: '\eab3';
}
.icon-portrait2:before {
  content: '\eab4';
}
.icon-user:before {
  content: '\eab5';
}
.icon-user-plus:before {
  content: '\eab6';
}
.icon-user-minus:before {
  content: '\eab7';
}
.icon-user-heart:before {
  content: '\eab8';
}
.icon-user-lock:before {
  content: '\eab9';
}
.icon-user-question:before {
  content: '\eaba';
}
.icon-user-bubble:before {
  content: '\eabb';
}
.icon-user-check:before {
  content: '\eabc';
}
.icon-user-cross:before {
  content: '\eabd';
}
.icon-user-edit:before {
  content: '\eabe';
}
.icon-user-gear:before {
  content: '\eabf';
}
.icon-user-info:before {
  content: '\eac0';
}
.icon-user-24:before {
  content: '\eac1';
}
.icon-support:before {
  content: '\eac2';
}
.icon-group:before {
  content: '\eac3';
}
.icon-group-plus:before {
  content: '\eac4';
}
.icon-group-minus:before {
  content: '\eac5';
}
.icon-group-edit:before {
  content: '\eac6';
}
.icon-group-circle:before {
  content: '\eac7';
}
.icon-head:before {
  content: '\eac8';
}
.icon-head-hearing:before {
  content: '\eac9';
}
.icon-head-speech:before {
  content: '\eaca';
}
.icon-head-question:before {
  content: '\eacb';
}
.icon-head-money:before {
  content: '\eacc';
}
.icon-head-processing:before {
  content: '\eacd';
}
.icon-head-bubble:before {
  content: '\eace';
}
.icon-portrait-male:before {
  content: '\eacf';
}
.icon-portrait-female:before {
  content: '\ead0';
}
.icon-baby1:before {
  content: '\ead1';
}
.icon-baby-crying:before {
  content: '\ead2';
}
.icon-baby-smile:before {
  content: '\ead3';
}
.icon-baby-tongue:before {
  content: '\ead4';
}
.icon-baby2:before {
  content: '\ead5';
}
.icon-baby-crying2:before {
  content: '\ead6';
}
.icon-baby3:before {
  content: '\ead7';
}
.icon-baby-tongue2:before {
  content: '\ead8';
}
.icon-pacifier:before {
  content: '\ead9';
}
.icon-baby-bottle:before {
  content: '\eada';
}
.icon-stroller:before {
  content: '\eadb';
}
.icon-standing:before {
  content: '\eadc';
}
.icon-walking:before {
  content: '\eadd';
}
.icon-waving:before {
  content: '\eade';
}
.icon-victory:before {
  content: '\eadf';
}
.icon-running:before {
  content: '\eae0';
}
.icon-jumping:before {
  content: '\eae1';
}
.icon-traveling:before {
  content: '\eae2';
}
.icon-stairs:before {
  content: '\eae3';
}
.icon-escalator-up:before {
  content: '\eae4';
}
.icon-escalator-down:before {
  content: '\eae5';
}
.icon-depressed:before {
  content: '\eae6';
}
.icon-male:before {
  content: '\eae7';
}
.icon-female:before {
  content: '\eae8';
}
.icon-gender:before {
  content: '\eae9';
}
.icon-gender2:before {
  content: '\eaea';
}
.icon-bathroom-scale:before {
  content: '\eaeb';
}
.icon-height:before {
  content: '\eaec';
}
.icon-button:before {
  content: '\eaed';
}
.icon-button2:before {
  content: '\eaee';
}
.icon-suit:before {
  content: '\eaef';
}
.icon-bow-tie:before {
  content: '\eaf0';
}
.icon-necktie:before {
  content: '\eaf1';
}
.icon-sock:before {
  content: '\eaf2';
}
.icon-sock-dirty:before {
  content: '\eaf3';
}
.icon-shoe:before {
  content: '\eaf4';
}
.icon-high-heels:before {
  content: '\eaf5';
}
.icon-lips:before {
  content: '\eaf6';
}
.icon-lipstick:before {
  content: '\eaf7';
}
.icon-hairdryer:before {
  content: '\eaf8';
}
.icon-hand-soap:before {
  content: '\eaf9';
}
.icon-ring:before {
  content: '\eafa';
}
.icon-mirror:before {
  content: '\eafb';
}
.icon-bowler-hat:before {
  content: '\eafc';
}
.icon-top-hat:before {
  content: '\eafd';
}
.icon-pants:before {
  content: '\eafe';
}
.icon-shorts:before {
  content: '\eaff';
}
.icon-underwear:before {
  content: '\eb00';
}
.icon-underwear2:before {
  content: '\eb01';
}
.icon-bra:before {
  content: '\eb02';
}
.icon-shirt:before {
  content: '\eb03';
}
.icon-gown:before {
  content: '\eb04';
}
.icon-hoodie:before {
  content: '\eb05';
}
.icon-hanger:before {
  content: '\eb06';
}
.icon-hanger2:before {
  content: '\eb07';
}
.icon-washing-machine:before {
  content: '\eb08';
}
.icon-laundry-time:before {
  content: '\eb09';
}
.icon-machine-wash:before {
  content: '\eb0a';
}
.icon-machine-wash-cold:before {
  content: '\eb0b';
}
.icon-machine-wash-warm:before {
  content: '\eb0c';
}
.icon-machine-wash-hot:before {
  content: '\eb0d';
}
.icon-iron:before {
  content: '\eb0e';
}
.icon-iron-low:before {
  content: '\eb0f';
}
.icon-iron-medium:before {
  content: '\eb10';
}
.icon-iron-high:before {
  content: '\eb11';
}
.icon-iron-no:before {
  content: '\eb12';
}
.icon-iron-steam:before {
  content: '\eb13';
}
.icon-iron-steam-no:before {
  content: '\eb14';
}
.icon-store:before {
  content: '\eb15';
}
.icon-store-24:before {
  content: '\eb16';
}
.icon-barcode1:before {
  content: '\eb17';
}
.icon-barcode2:before {
  content: '\eb18';
}
.icon-barcode3:before {
  content: '\eb19';
}
.icon-qrcode:before {
  content: '\eb1a';
}
.icon-box2:before {
  content: '\eb1b';
}
.icon-box-check1:before {
  content: '\eb1c';
}
.icon-box-cross:before {
  content: '\eb1d';
}
.icon-box3:before {
  content: '\eb1e';
}
.icon-box-check2:before {
  content: '\eb1f';
}
.icon-box-cross2:before {
  content: '\eb20';
}
.icon-basket:before {
  content: '\eb21';
}
.icon-bag:before {
  content: '\eb22';
}
.icon-bag-full:before {
  content: '\eb23';
}
.icon-bag-plus:before {
  content: '\eb24';
}
.icon-bag-minus:before {
  content: '\eb25';
}
.icon-bag-checkout:before {
  content: '\eb26';
}
.icon-bag-check:before {
  content: '\eb27';
}
.icon-bag-cross:before {
  content: '\eb28';
}
.icon-cart:before {
  content: '\eb29';
}
.icon-cart-full:before {
  content: '\eb2a';
}
.icon-cart-plus1:before {
  content: '\eb2b';
}
.icon-cart-minus1:before {
  content: '\eb2c';
}
.icon-cart-load:before {
  content: '\eb2d';
}
.icon-cart-unload:before {
  content: '\eb2e';
}
.icon-cart-manage:before {
  content: '\eb2f';
}
.icon-cart-checkout:before {
  content: '\eb30';
}
.icon-cart-check:before {
  content: '\eb31';
}
.icon-cart-cross:before {
  content: '\eb32';
}
.icon-tag:before {
  content: '\eb33';
}
.icon-tag2:before {
  content: '\eb34';
}
.icon-receipt:before {
  content: '\eb35';
}
.icon-wallet:before {
  content: '\eb36';
}
.icon-debit-card:before {
  content: '\eb37';
}
.icon-piggy-bank:before {
  content: '\eb38';
}
.icon-money-pouch:before {
  content: '\eb39';
}
.icon-money-pouch-euro:before {
  content: '\eb3a';
}
.icon-money-pouch-pound:before {
  content: '\eb3b';
}
.icon-money-pouch-yen:before {
  content: '\eb3c';
}
.icon-money-pouch-ruble:before {
  content: '\eb3d';
}
.icon-bill:before {
  content: '\eb3e';
}
.icon-bill2:before {
  content: '\eb3f';
}
.icon-bill-dollar:before {
  content: '\eb40';
}
.icon-bill-euro:before {
  content: '\eb41';
}
.icon-bill-pound:before {
  content: '\eb42';
}
.icon-bill-yen:before {
  content: '\eb43';
}
.icon-bill-ruble:before {
  content: '\eb44';
}
.icon-bill-stack:before {
  content: '\eb45';
}
.icon-bill-stack-dollar:before {
  content: '\eb46';
}
.icon-bill-stack-euro:before {
  content: '\eb47';
}
.icon-bill-stack-pound:before {
  content: '\eb48';
}
.icon-bill-stack-yen:before {
  content: '\eb49';
}
.icon-bill-stack-ruble:before {
  content: '\eb4a';
}
.icon-calculator1:before {
  content: '\eb4b';
}
.icon-calculator2:before {
  content: '\eb4c';
}
.icon-safe:before {
  content: '\eb4d';
}
.icon-telephone:before {
  content: '\eb4e';
}
.icon-phone-end:before {
  content: '\eb4f';
}
.icon-missed-incoming:before {
  content: '\eb50';
}
.icon-missed-outgoing:before {
  content: '\eb51';
}
.icon-phone-lock:before {
  content: '\eb52';
}
.icon-phone-loudspeaker:before {
  content: '\eb53';
}
.icon-phone-bubble:before {
  content: '\eb54';
}
.icon-phone-pause:before {
  content: '\eb55';
}
.icon-phone-outgoing:before {
  content: '\eb56';
}
.icon-phone-incoming:before {
  content: '\eb57';
}
.icon-phone-in-out:before {
  content: '\eb58';
}
.icon-phone-cross:before {
  content: '\eb59';
}
.icon-phone-user:before {
  content: '\eb5a';
}
.icon-phone-time:before {
  content: '\eb5b';
}
.icon-phone-24:before {
  content: '\eb5c';
}
.icon-phone-sip:before {
  content: '\eb5d';
}
.icon-phone-plus:before {
  content: '\eb5e';
}
.icon-phone-minus:before {
  content: '\eb5f';
}
.icon-voicemail:before {
  content: '\eb60';
}
.icon-telephone2:before {
  content: '\eb61';
}
.icon-telephone3:before {
  content: '\eb62';
}
.icon-pushpin:before {
  content: '\eb63';
}
.icon-pushpin-cross:before {
  content: '\eb64';
}
.icon-pushpin2:before {
  content: '\eb65';
}
.icon-map-marker:before {
  content: '\eb66';
}
.icon-map-marker-user:before {
  content: '\eb67';
}
.icon-map-marker-down:before {
  content: '\eb68';
}
.icon-map-marker-plus:before {
  content: '\eb69';
}
.icon-map-marker-minus:before {
  content: '\eb6a';
}
.icon-map-marker-check:before {
  content: '\eb6b';
}
.icon-map-marker-cross:before {
  content: '\eb6c';
}
.icon-map-marker-edit:before {
  content: '\eb6d';
}
.icon-helm:before {
  content: '\eb6e';
}
.icon-compass2:before {
  content: '\eb6f';
}
.icon-map:before {
  content: '\eb70';
}
.icon-map2:before {
  content: '\eb71';
}
.icon-compass3:before {
  content: '\eb72';
}
.icon-road-sign:before {
  content: '\eb73';
}
.icon-calendar1:before {
  content: '\eb74';
}
.icon-calendar2:before {
  content: '\eb75';
}
.icon-calendar3:before {
  content: '\eb76';
}
.icon-calendar4:before {
  content: '\eb77';
}
.icon-calendar-time:before {
  content: '\eb78';
}
.icon-calendar-check1:before {
  content: '\eb79';
}
.icon-calendar-cross:before {
  content: '\eb7a';
}
.icon-calendar-plus1:before {
  content: '\eb7b';
}
.icon-calendar-minus1:before {
  content: '\eb7c';
}
.icon-calendar-user:before {
  content: '\eb7d';
}
.icon-mouse:before {
  content: '\eb7e';
}
.icon-mouse-wireless:before {
  content: '\eb7f';
}
.icon-mouse-click-left:before {
  content: '\eb80';
}
.icon-mouse-click-right:before {
  content: '\eb81';
}
.icon-mouse-click-both:before {
  content: '\eb82';
}
.icon-mouse-network:before {
  content: '\eb83';
}
.icon-mouse-check:before {
  content: '\eb84';
}
.icon-mouse-cross:before {
  content: '\eb85';
}
.icon-keyboard:before {
  content: '\eb86';
}
.icon-keyboard-wireless:before {
  content: '\eb87';
}
.icon-keyboard-show:before {
  content: '\eb88';
}
.icon-keyboard-hide:before {
  content: '\eb89';
}
.icon-keyboard-key:before {
  content: '\eb8a';
}
.icon-keyboard-key2:before {
  content: '\eb8b';
}
.icon-keyboard-key-down:before {
  content: '\eb8c';
}
.icon-keyboard-key-up:before {
  content: '\eb8d';
}
.icon-delete:before {
  content: '\eb8e';
}
.icon-spell-check:before {
  content: '\eb8f';
}
.icon-circle-extract:before {
  content: '\eb90';
}
.icon-circle-insert:before {
  content: '\eb91';
}
.icon-widescreen:before {
  content: '\eb92';
}
.icon-widescreen-play:before {
  content: '\eb93';
}
.icon-widescreen-user:before {
  content: '\eb94';
}
.icon-signal:before {
  content: '\eb95';
}
.icon-signal-lock:before {
  content: '\eb96';
}
.icon-signal-3of4:before {
  content: '\eb97';
}
.icon-signal-2of4:before {
  content: '\eb98';
}
.icon-signal-1of4:before {
  content: '\eb99';
}
.icon-signal-error:before {
  content: '\eb9a';
}
.icon-sim:before {
  content: '\eb9b';
}
.icon-microsd:before {
  content: '\eb9c';
}
.icon-microsd-plus:before {
  content: '\eb9d';
}
.icon-usb-drive:before {
  content: '\eb9e';
}
.icon-usb:before {
  content: '\eb9f';
}
.icon-usb-question:before {
  content: '\eba0';
}
.icon-usb-warning:before {
  content: '\eba1';
}
.icon-usb-check:before {
  content: '\eba2';
}
.icon-usb-cross:before {
  content: '\eba3';
}
.icon-usb-plus:before {
  content: '\eba4';
}
.icon-usb-minus:before {
  content: '\eba5';
}
.icon-smartphone:before {
  content: '\eba6';
}
.icon-smartphone2:before {
  content: '\eba7';
}
.icon-smartphone-vibration:before {
  content: '\eba8';
}
.icon-smartphone-speakers:before {
  content: '\eba9';
}
.icon-smartphone-pencil:before {
  content: '\ebaa';
}
.icon-smartphone-ringtone:before {
  content: '\ebab';
}
.icon-smartphone-silent:before {
  content: '\ebac';
}
.icon-smartphone-time:before {
  content: '\ebad';
}
.icon-smartphone-sync:before {
  content: '\ebae';
}
.icon-smartphone-code:before {
  content: '\ebaf';
}
.icon-smartphone-backup:before {
  content: '\ebb0';
}
.icon-smartphone-info:before {
  content: '\ebb1';
}
.icon-smartphone-lock:before {
  content: '\ebb2';
}
.icon-smartphone-check:before {
  content: '\ebb3';
}
.icon-smartphone-warning:before {
  content: '\ebb4';
}
.icon-smartphone-text:before {
  content: '\ebb5';
}
.icon-smartphone-user:before {
  content: '\ebb6';
}
.icon-smartphone-rotate:before {
  content: '\ebb7';
}
.icon-smartphone-transfer:before {
  content: '\ebb8';
}
.icon-tablet:before {
  content: '\ebb9';
}
.icon-tablet2:before {
  content: '\ebba';
}
.icon-laptop:before {
  content: '\ebbb';
}
.icon-laptop-cross:before {
  content: '\ebbc';
}
.icon-laptop-warning:before {
  content: '\ebbd';
}
.icon-laptop-play:before {
  content: '\ebbe';
}
.icon-laptop-code:before {
  content: '\ebbf';
}
.icon-laptop-education:before {
  content: '\ebc0';
}
.icon-laptop-user:before {
  content: '\ebc1';
}
.icon-laptop-share:before {
  content: '\ebc2';
}
.icon-laptop-phone:before {
  content: '\ebc3';
}
.icon-laptop-phone-sync:before {
  content: '\ebc4';
}
.icon-webcam:before {
  content: '\ebc5';
}
.icon-monitor:before {
  content: '\ebc6';
}
.icon-monitor-play:before {
  content: '\ebc7';
}
.icon-monitor-share:before {
  content: '\ebc8';
}
.icon-thin:before {
  content: '\ebc9';
}
.icon-external:before {
  content: '\ebca';
}
.icon-external2:before {
  content: '\ebcb';
}
.icon-window2:before {
  content: '\ebcc';
}
.icon-window-tab:before {
  content: '\ebcd';
}
.icon-window-tab-plus:before {
  content: '\ebce';
}
.icon-tv:before {
  content: '\ebcf';
}
.icon-radio:before {
  content: '\ebd0';
}
.icon-remote-control:before {
  content: '\ebd1';
}
.icon-remote-control2:before {
  content: '\ebd2';
}
.icon-power-switch:before {
  content: '\ebd3';
}
.icon-power-switch2:before {
  content: '\ebd4';
}
.icon-power2:before {
  content: '\ebd5';
}
.icon-power-auto:before {
  content: '\ebd6';
}
.icon-power-cross:before {
  content: '\ebd7';
}
.icon-power-warning:before {
  content: '\ebd8';
}
.icon-power-dollar:before {
  content: '\ebd9';
}
.icon-lamp:before {
  content: '\ebda';
}
.icon-lamp-bright:before {
  content: '\ebdb';
}
.icon-lamp-check:before {
  content: '\ebdc';
}
.icon-lamp-plus:before {
  content: '\ebdd';
}
.icon-lamp-minus:before {
  content: '\ebde';
}
.icon-lamp-time:before {
  content: '\ebdf';
}
.icon-lamp-gear:before {
  content: '\ebe0';
}
.icon-flashlight:before {
  content: '\ebe1';
}
.icon-flashlight-bright:before {
  content: '\ebe2';
}
.icon-floor-lamp:before {
  content: '\ebe3';
}
.icon-reflection:before {
  content: '\ebe4';
}
.icon-solar-power:before {
  content: '\ebe5';
}
.icon-solar-power2:before {
  content: '\ebe6';
}
.icon-solar-panel:before {
  content: '\ebe7';
}
.icon-plug:before {
  content: '\ebe8';
}
.icon-plug-cross:before {
  content: '\ebe9';
}
.icon-socket:before {
  content: '\ebea';
}
.icon-battery-bolt1:before {
  content: '\ebeb';
}
.icon-battery-plug:before {
  content: '\ebec';
}
.icon-battery-droplet:before {
  content: '\ebed';
}
.icon-battery-plus:before {
  content: '\ebee';
}
.icon-battery-question:before {
  content: '\ebef';
}
.icon-battery-warning:before {
  content: '\ebf0';
}
.icon-battery-check:before {
  content: '\ebf1';
}
.icon-battery-cross:before {
  content: '\ebf2';
}
.icon-battery-empty1:before {
  content: '\ebf3';
}
.icon-battery-10:before {
  content: '\ebf4';
}
.icon-battery-25:before {
  content: '\ebf5';
}
.icon-battery-40:before {
  content: '\ebf6';
}
.icon-battery-50:before {
  content: '\ebf7';
}
.icon-battery-60:before {
  content: '\ebf8';
}
.icon-battery-75:before {
  content: '\ebf9';
}
.icon-battery-90:before {
  content: '\ebfa';
}
.icon-battery-100:before {
  content: '\ebfb';
}
.icon-battery-plug2:before {
  content: '\ebfc';
}
.icon-battery-plug-10:before {
  content: '\ebfd';
}
.icon-battery-plug-25:before {
  content: '\ebfe';
}
.icon-battery-plug-40:before {
  content: '\ebff';
}
.icon-battery-plug-50:before {
  content: '\ec00';
}
.icon-battery-plug-60:before {
  content: '\ec01';
}
.icon-battery-plug-75:before {
  content: '\ec02';
}
.icon-battery-plug-90:before {
  content: '\ec03';
}
.icon-battery-plug-100:before {
  content: '\ec04';
}
.icon-led:before {
  content: '\ec05';
}
.icon-led-bright:before {
  content: '\ec06';
}
.icon-resistor:before {
  content: '\ec07';
}
.icon-capacitor:before {
  content: '\ec08';
}
.icon-ic:before {
  content: '\ec09';
}
.icon-cpu:before {
  content: '\ec0a';
}
.icon-cpu-x64:before {
  content: '\ec0b';
}
.icon-cpu-x86:before {
  content: '\ec0c';
}
.icon-bubble-text:before {
  content: '\ec0d';
}
.icon-bubble-check:before {
  content: '\ec0e';
}
.icon-bubble-cross:before {
  content: '\ec0f';
}
.icon-bubble-time:before {
  content: '\ec10';
}
.icon-bubbles:before {
  content: '\ec11';
}
.icon-bubble:before {
  content: '\ec12';
}
.icon-bubble-shiny:before {
  content: '\ec13';
}
.icon-bubbles2:before {
  content: '\ec14';
}
.icon-bubble-dots:before {
  content: '\ec15';
}
.icon-bubble-paperclip:before {
  content: '\ec16';
}
.icon-bubble-exclamation:before {
  content: '\ec17';
}
.icon-bubble-question:before {
  content: '\ec18';
}
.icon-bubble-text2:before {
  content: '\ec19';
}
.icon-bubble-writing:before {
  content: '\ec1a';
}
.icon-bubble-picture:before {
  content: '\ec1b';
}
.icon-bubble-music:before {
  content: '\ec1c';
}
.icon-bubble-music2:before {
  content: '\ec1d';
}
.icon-bubble-video:before {
  content: '\ec1e';
}
.icon-bubble-friend:before {
  content: '\ec1f';
}
.icon-bubble-friend-plus:before {
  content: '\ec20';
}
.icon-bubble-quote:before {
  content: '\ec21';
}
.icon-bubble-heart:before {
  content: '\ec22';
}
.icon-bubble-emoji:before {
  content: '\ec23';
}
.icon-bubble-poker-face:before {
  content: '\ec24';
}
.icon-bubble-sad:before {
  content: '\ec25';
}
.icon-bubble-mad:before {
  content: '\ec26';
}
.icon-bubble-shocked:before {
  content: '\ec27';
}
.icon-quote-left:before {
  content: '\ec28';
}
.icon-quote-right:before {
  content: '\ec29';
}
.icon-dna:before {
  content: '\ec2a';
}
.icon-dna-code:before {
  content: '\ec2b';
}
.icon-lungs:before {
  content: '\ec2c';
}
.icon-inhaler:before {
  content: '\ec2d';
}
.icon-heart2:before {
  content: '\ec2e';
}
.icon-heart3:before {
  content: '\ec2f';
}
.icon-heart-plus:before {
  content: '\ec30';
}
.icon-heart-time:before {
  content: '\ec31';
}
.icon-heart-pulse:before {
  content: '\ec32';
}
.icon-stethoscope:before {
  content: '\ec33';
}
.icon-pulse:before {
  content: '\ec34';
}
.icon-syringe:before {
  content: '\ec35';
}
.icon-bottle2:before {
  content: '\ec36';
}
.icon-pills:before {
  content: '\ec37';
}
.icon-first-aid-kit:before {
  content: '\ec38';
}
.icon-lifebuoy:before {
  content: '\ec39';
}
.icon-bandage1:before {
  content: '\ec3a';
}
.icon-bandages:before {
  content: '\ec3b';
}
.icon-thermometer:before {
  content: '\ec3c';
}
.icon-thermometer-plus:before {
  content: '\ec3d';
}
.icon-thermometer-minus:before {
  content: '\ec3e';
}
.icon-thermometer-sun:before {
  content: '\ec3f';
}
.icon-thermometer-snow:before {
  content: '\ec40';
}
.icon-thermometer-gear:before {
  content: '\ec41';
}
.icon-microscope:before {
  content: '\ec42';
}
.icon-atom1:before {
  content: '\ec43';
}
.icon-test-tube:before {
  content: '\ec44';
}
.icon-beaker:before {
  content: '\ec45';
}
.icon-rb-flask:before {
  content: '\ec46';
}
.icon-rb-flask-warning:before {
  content: '\ec47';
}
.icon-flask:before {
  content: '\ec48';
}
.icon-flask-warning:before {
  content: '\ec49';
}
.icon-skull:before {
  content: '\ec4a';
}
.icon-bone1:before {
  content: '\ec4b';
}
.icon-chart-upward:before {
  content: '\ec4c';
}
.icon-chart-downward:before {
  content: '\ec4d';
}
.icon-scatterplot:before {
  content: '\ec4e';
}
.icon-chart:before {
  content: '\ec4f';
}
.icon-pie-chart:before {
  content: '\ec50';
}
.icon-cake:before {
  content: '\ec51';
}
.icon-gift:before {
  content: '\ec52';
}
.icon-balloon1:before {
  content: '\ec53';
}
.icon-flower:before {
  content: '\ec54';
}
.icon-flower2:before {
  content: '\ec55';
}
.icon-flower3:before {
  content: '\ec56';
}
.icon-rank:before {
  content: '\ec57';
}
.icon-rank2:before {
  content: '\ec58';
}
.icon-rank3:before {
  content: '\ec59';
}
.icon-crown1:before {
  content: '\ec5a';
}
.icon-emerald:before {
  content: '\ec5b';
}
.icon-ruby:before {
  content: '\ec5c';
}
.icon-diamond1:before {
  content: '\ec5d';
}
.icon-diamond2:before {
  content: '\ec5e';
}
.icon-tea-bag:before {
  content: '\ec5f';
}
.icon-teacup:before {
  content: '\ec60';
}
.icon-glass:before {
  content: '\ec61';
}
.icon-glass-broken:before {
  content: '\ec62';
}
.icon-glass-cocktail:before {
  content: '\ec63';
}
.icon-tankard:before {
  content: '\ec64';
}
.icon-glass2:before {
  content: '\ec65';
}
.icon-fork:before {
  content: '\ec66';
}
.icon-knife:before {
  content: '\ec67';
}
.icon-spoon:before {
  content: '\ec68';
}
.icon-fork-knife:before {
  content: '\ec69';
}
.icon-fork-spoon:before {
  content: '\ec6a';
}
.icon-fork-knife2:before {
  content: '\ec6b';
}
.icon-fork-spoon2:before {
  content: '\ec6c';
}
.icon-table:before {
  content: '\ec6d';
}
.icon-chef:before {
  content: '\ec6e';
}
.icon-pot:before {
  content: '\ec6f';
}
.icon-pot-time:before {
  content: '\ec70';
}
.icon-pot-hot:before {
  content: '\ec71';
}
.icon-microwave:before {
  content: '\ec72';
}
.icon-refrigerator:before {
  content: '\ec73';
}
.icon-kitchen-scale:before {
  content: '\ec74';
}
.icon-weight:before {
  content: '\ec75';
}
.icon-weight-1kg:before {
  content: '\ec76';
}
.icon-weight-1lb:before {
  content: '\ec77';
}
.icon-weight-1oz:before {
  content: '\ec78';
}
.icon-mixer:before {
  content: '\ec79';
}
.icon-rolling-pin:before {
  content: '\ec7a';
}
.icon-egg:before {
  content: '\ec7b';
}
.icon-egg-broken:before {
  content: '\ec7c';
}
.icon-platter:before {
  content: '\ec7d';
}
.icon-pig:before {
  content: '\ec7e';
}
.icon-steak:before {
  content: '\ec7f';
}
.icon-fries:before {
  content: '\ec80';
}
.icon-burger1:before {
  content: '\ec81';
}
.icon-hot-dog:before {
  content: '\ec82';
}
.icon-pizza:before {
  content: '\ec83';
}
.icon-chicken-leg:before {
  content: '\ec84';
}
.icon-fish:before {
  content: '\ec85';
}
.icon-carrot1:before {
  content: '\ec86';
}
.icon-cheese1:before {
  content: '\ec87';
}
.icon-fried-egg:before {
  content: '\ec88';
}
.icon-milk-bottle:before {
  content: '\ec89';
}
.icon-pitcher:before {
  content: '\ec8a';
}
.icon-toaster:before {
  content: '\ec8b';
}
.icon-toast:before {
  content: '\ec8c';
}
.icon-ice-cream-bar:before {
  content: '\ec8d';
}
.icon-ice-cream:before {
  content: '\ec8e';
}
.icon-candy:before {
  content: '\ec8f';
}
.icon-lollipop:before {
  content: '\ec90';
}
.icon-donut:before {
  content: '\ec91';
}
.icon-coffee-maker:before {
  content: '\ec92';
}
.icon-coffee-pot1:before {
  content: '\ec93';
}
.icon-paper-cup:before {
  content: '\ec94';
}
.icon-mug:before {
  content: '\ec95';
}
.icon-coffee-bean1:before {
  content: '\ec96';
}
.icon-cherry:before {
  content: '\ec97';
}
.icon-grape:before {
  content: '\ec98';
}
.icon-citrus1:before {
  content: '\ec99';
}
.icon-apple:before {
  content: '\ec9a';
}
.icon-apple-check:before {
  content: '\ec9b';
}
.icon-leaf:before {
  content: '\ec9c';
}
.icon-leaf-plus:before {
  content: '\ec9d';
}
.icon-leaf-check:before {
  content: '\ec9e';
}
.icon-leaf-cross:before {
  content: '\ec9f';
}
.icon-cactus1:before {
  content: '\eca0';
}
.icon-diving-mask:before {
  content: '\eca1';
}
.icon-fish-bowl:before {
  content: '\eca2';
}
.icon-hook:before {
  content: '\eca3';
}
.icon-paw:before {
  content: '\eca4';
}
.icon-footprint:before {
  content: '\eca5';
}
.icon-speed-slow:before {
  content: '\eca6';
}
.icon-speed-medium:before {
  content: '\eca7';
}
.icon-speed-fast:before {
  content: '\eca8';
}
.icon-rocket:before {
  content: '\eca9';
}
.icon-flying-saucer:before {
  content: '\ecaa';
}
.icon-flying-saucer2:before {
  content: '\ecab';
}
.icon-hammer2:before {
  content: '\ecac';
}
.icon-balance:before {
  content: '\ecad';
}
.icon-briefcase1:before {
  content: '\ecae';
}
.icon-briefcase-money:before {
  content: '\ecaf';
}
.icon-briefcase-question:before {
  content: '\ecb0';
}
.icon-luggage-weight:before {
  content: '\ecb1';
}
.icon-hand-truck:before {
  content: '\ecb2';
}
.icon-construction:before {
  content: '\ecb3';
}
.icon-construction-cone:before {
  content: '\ecb4';
}
.icon-traffic-lights:before {
  content: '\ecb5';
}
.icon-siren:before {
  content: '\ecb6';
}
.icon-siren-bright:before {
  content: '\ecb7';
}
.icon-road:before {
  content: '\ecb8';
}
.icon-disc-brake:before {
  content: '\ecb9';
}
.icon-engine1:before {
  content: '\ecba';
}
.icon-oil-pressure:before {
  content: '\ecbb';
}
.icon-car-battery1:before {
  content: '\ecbc';
}
.icon-charging-station1:before {
  content: '\ecbd';
}
.icon-gas-station:before {
  content: '\ecbe';
}
.icon-gallon:before {
  content: '\ecbf';
}
.icon-transmission:before {
  content: '\ecc0';
}
.icon-car1:before {
  content: '\ecc1';
}
.icon-car-plug:before {
  content: '\ecc2';
}
.icon-car-check:before {
  content: '\ecc3';
}
.icon-car-cross:before {
  content: '\ecc4';
}
.icon-car-wash1:before {
  content: '\ecc5';
}
.icon-car-lock:before {
  content: '\ecc6';
}
.icon-car-parking:before {
  content: '\ecc7';
}
.icon-bus1:before {
  content: '\ecc8';
}
.icon-bus2:before {
  content: '\ecc9';
}
.icon-car2:before {
  content: '\ecca';
}
.icon-car-lock2:before {
  content: '\eccb';
}
.icon-car-parking2:before {
  content: '\eccc';
}
.icon-police:before {
  content: '\eccd';
}
.icon-taxi:before {
  content: '\ecce';
}
.icon-jet-engine:before {
  content: '\eccf';
}
.icon-airplane:before {
  content: '\ecd0';
}
.icon-airplane-cross:before {
  content: '\ecd1';
}
.icon-quadcopter:before {
  content: '\ecd2';
}
.icon-helicopter:before {
  content: '\ecd3';
}
.icon-helipad:before {
  content: '\ecd4';
}
.icon-helipad2:before {
  content: '\ecd5';
}
.icon-first-aid:before {
  content: '\ecd6';
}
.icon-ambulance:before {
  content: '\ecd7';
}
.icon-truck:before {
  content: '\ecd8';
}
.icon-truck-arrow:before {
  content: '\ecd9';
}
.icon-truck-time:before {
  content: '\ecda';
}
.icon-truck-check:before {
  content: '\ecdb';
}
.icon-truck-cross:before {
  content: '\ecdc';
}
.icon-truck-scale:before {
  content: '\ecdd';
}
.icon-train:before {
  content: '\ecde';
}
.icon-yacht:before {
  content: '\ecdf';
}
.icon-ship:before {
  content: '\ece0';
}
.icon-anchor1:before {
  content: '\ece1';
}
.icon-lighthouse:before {
  content: '\ece2';
}
.icon-boat:before {
  content: '\ece3';
}
.icon-bicycle1:before {
  content: '\ece4';
}
.icon-bicycle-riding:before {
  content: '\ece5';
}
.icon-skating:before {
  content: '\ece6';
}
.icon-hiking:before {
  content: '\ece7';
}
.icon-rowing:before {
  content: '\ece8';
}
.icon-swimming:before {
  content: '\ece9';
}
.icon-swimming-warning:before {
  content: '\ecea';
}
.icon-diving:before {
  content: '\eceb';
}
.icon-diving-warning:before {
  content: '\ecec';
}
.icon-snorkeling:before {
  content: '\eced';
}
.icon-weight-lifting:before {
  content: '\ecee';
}
.icon-dumbbell1:before {
  content: '\ecef';
}
.icon-baseball:before {
  content: '\ecf0';
}
.icon-baseball-bat:before {
  content: '\ecf1';
}
.icon-football:before {
  content: '\ecf2';
}
.icon-hockey:before {
  content: '\ecf3';
}
.icon-golf:before {
  content: '\ecf4';
}
.icon-bowling-ball1:before {
  content: '\ecf5';
}
.icon-tennis:before {
  content: '\ecf6';
}
.icon-tennis-ball:before {
  content: '\ecf7';
}
.icon-pool:before {
  content: '\ecf8';
}
.icon-ping-pong:before {
  content: '\ecf9';
}
.icon-archery:before {
  content: '\ecfa';
}
.icon-target:before {
  content: '\ecfb';
}
.icon-fencing:before {
  content: '\ecfc';
}
.icon-slingshot:before {
  content: '\ecfd';
}
.icon-soccer:before {
  content: '\ecfe';
}
.icon-basketball:before {
  content: '\ecff';
}
.icon-whistle:before {
  content: '\ed00';
}
.icon-cube1:before {
  content: '\ed01';
}
.icon-cube-front:before {
  content: '\ed02';
}
.icon-cube-side:before {
  content: '\ed03';
}
.icon-cube-top:before {
  content: '\ed04';
}
.icon-cube-bottom:before {
  content: '\ed05';
}
.icon-cube-3d:before {
  content: '\ed06';
}
.icon-puzzle:before {
  content: '\ed07';
}
.icon-puzzle-down:before {
  content: '\ed08';
}
.icon-puzzle-check:before {
  content: '\ed09';
}
.icon-puzzle-cross:before {
  content: '\ed0a';
}
.icon-sunglasses:before {
  content: '\ed0b';
}
.icon-glasses:before {
  content: '\ed0c';
}
.icon-glasses2:before {
  content: '\ed0d';
}
.icon-accessibility:before {
  content: '\ed0e';
}
.icon-wheelchair:before {
  content: '\ed0f';
}
.icon-fence1:before {
  content: '\ed10';
}
.icon-bricks:before {
  content: '\ed11';
}
.icon-apps:before {
  content: '\ed12';
}
.icon-apps2:before {
  content: '\ed13';
}
.icon-apps3:before {
  content: '\ed14';
}
.icon-apps4:before {
  content: '\ed15';
}
.icon-pointer:before {
  content: '\ed16';
}
.icon-select:before {
  content: '\ed17';
}
.icon-branches2:before {
  content: '\ed18';
}
.icon-earth:before {
  content: '\ed19';
}
.icon-earth-lock:before {
  content: '\ed1a';
}
.icon-network:before {
  content: '\ed1b';
}
.icon-planet:before {
  content: '\ed1c';
}
.icon-smiley:before {
  content: '\ed1d';
}
.icon-wink:before {
  content: '\ed1e';
}
.icon-smirk:before {
  content: '\ed1f';
}
.icon-smirk-wink:before {
  content: '\ed20';
}
.icon-neutral:before {
  content: '\ed21';
}
.icon-happy:before {
  content: '\ed22';
}
.icon-grin:before {
  content: '\ed23';
}
.icon-grin-braces:before {
  content: '\ed24';
}
.icon-grin-happy:before {
  content: '\ed25';
}
.icon-poker-face:before {
  content: '\ed26';
}
.icon-pleased:before {
  content: '\ed27';
}
.icon-sad:before {
  content: '\ed28';
}
.icon-tongue:before {
  content: '\ed29';
}
.icon-tongue-wink:before {
  content: '\ed2a';
}
.icon-wondering:before {
  content: '\ed2b';
}
.icon-shocked:before {
  content: '\ed2c';
}
.icon-shocked2:before {
  content: '\ed2d';
}
.icon-dork:before {
  content: '\ed2e';
}
.icon-dead:before {
  content: '\ed2f';
}
.icon-confused:before {
  content: '\ed30';
}
.icon-baffled:before {
  content: '\ed31';
}
.icon-mad:before {
  content: '\ed32';
}
.icon-angry:before {
  content: '\ed33';
}
.icon-grin-evil:before {
  content: '\ed34';
}
.icon-evil:before {
  content: '\ed35';
}
.icon-ninja:before {
  content: '\ed36';
}
.icon-insomnia:before {
  content: '\ed37';
}
.icon-zipped:before {
  content: '\ed38';
}
.icon-skeptical:before {
  content: '\ed39';
}
.icon-sophisticated:before {
  content: '\ed3a';
}
.icon-geek:before {
  content: '\ed3b';
}
.icon-cool:before {
  content: '\ed3c';
}
.icon-dreaming:before {
  content: '\ed3d';
}
.icon-scared:before {
  content: '\ed3e';
}
.icon-robot:before {
  content: '\ed3f';
}
.icon-alien1:before {
  content: '\ed40';
}
.icon-tombstone:before {
  content: '\ed41';
}
.icon-ghost:before {
  content: '\ed42';
}
.icon-ghost-neutral:before {
  content: '\ed43';
}
.icon-ghost-dead:before {
  content: '\ed44';
}
.icon-crystal-ball:before {
  content: '\ed45';
}
.icon-witch-hat:before {
  content: '\ed46';
}
.icon-halloween:before {
  content: '\ed47';
}
.icon-snowman:before {
  content: '\ed48';
}
.icon-christmas-tree:before {
  content: '\ed49';
}
.icon-easter-egg:before {
  content: '\ed4a';
}
.icon-mustache:before {
  content: '\ed4b';
}
.icon-mustache-glasses:before {
  content: '\ed4c';
}
.icon-mustache-monocle:before {
  content: '\ed4d';
}
.icon-cigar:before {
  content: '\ed4e';
}
.icon-cigar-warning:before {
  content: '\ed4f';
}
.icon-cigar-no:before {
  content: '\ed50';
}
.icon-bell1:before {
  content: '\ed51';
}
.icon-bell-ringing:before {
  content: '\ed52';
}
.icon-bell2:before {
  content: '\ed53';
}
.icon-bell-plus1:before {
  content: '\ed54';
}
.icon-bell-minus:before {
  content: '\ed55';
}
.icon-bell-ringing2:before {
  content: '\ed56';
}
.icon-bell-snooze:before {
  content: '\ed57';
}
.icon-bell-music:before {
  content: '\ed58';
}
.icon-bell-warning:before {
  content: '\ed59';
}
.icon-megaphone2:before {
  content: '\ed5a';
}
.icon-volume-100:before {
  content: '\ed5b';
}
.icon-volume-75:before {
  content: '\ed5c';
}
.icon-volume-50:before {
  content: '\ed5d';
}
.icon-volume-25:before {
  content: '\ed5e';
}
.icon-volume-0:before {
  content: '\ed5f';
}
.icon-volume-cross:before {
  content: '\ed60';
}
.icon-network2:before {
  content: '\ed61';
}
.icon-connection:before {
  content: '\ed62';
}
.icon-connection-warning:before {
  content: '\ed63';
}
.icon-router:before {
  content: '\ed64';
}
.icon-router-warning:before {
  content: '\ed65';
}
.icon-wifi-100:before {
  content: '\ed66';
}
.icon-wifi-lock:before {
  content: '\ed67';
}
.icon-wifi-cross:before {
  content: '\ed68';
}
.icon-wifi-75:before {
  content: '\ed69';
}
.icon-wifi-50:before {
  content: '\ed6a';
}
.icon-wifi-25:before {
  content: '\ed6b';
}
.icon-wifi-100-warning:before {
  content: '\ed6c';
}
.icon-wifi-75-warning:before {
  content: '\ed6d';
}
.icon-wifi-50-warning:before {
  content: '\ed6e';
}
.icon-wifi-25-warning:before {
  content: '\ed6f';
}
.icon-stream:before {
  content: '\ed70';
}
.icon-stream-check:before {
  content: '\ed71';
}
.icon-stream-cross:before {
  content: '\ed72';
}
.icon-stream-warning:before {
  content: '\ed73';
}
.icon-communication:before {
  content: '\ed74';
}
.icon-communication-cross:before {
  content: '\ed75';
}
.icon-hotspot:before {
  content: '\ed76';
}
.icon-hotspot2:before {
  content: '\ed77';
}
.icon-antenna:before {
  content: '\ed78';
}
.icon-satellite:before {
  content: '\ed79';
}
.icon-satellite-dish:before {
  content: '\ed7a';
}
.icon-mic:before {
  content: '\ed7b';
}
.icon-mic-cross:before {
  content: '\ed7c';
}
.icon-mic-warning:before {
  content: '\ed7d';
}
.icon-mic-settings:before {
  content: '\ed7e';
}
.icon-mic-two-way:before {
  content: '\ed7f';
}
.icon-mic2:before {
  content: '\ed80';
}
.icon-mic-cross2:before {
  content: '\ed81';
}
.icon-mic-warning2:before {
  content: '\ed82';
}
.icon-spotlights:before {
  content: '\ed83';
}
.icon-hourglass:before {
  content: '\ed84';
}
.icon-spinner:before {
  content: '\ed85';
}
.icon-spinner2:before {
  content: '\ed86';
}
.icon-spinner3:before {
  content: '\ed87';
}
.icon-spinner4:before {
  content: '\ed88';
}
.icon-undo:before {
  content: '\ed89';
}
.icon-redo:before {
  content: '\ed8a';
}
.icon-leap-backward:before {
  content: '\ed8b';
}
.icon-leap-forward:before {
  content: '\ed8c';
}
.icon-cw:before {
  content: '\ed8d';
}
.icon-sync:before {
  content: '\ed8e';
}
.icon-cw-bolt:before {
  content: '\ed8f';
}
.icon-sync-warning:before {
  content: '\ed90';
}
.icon-sync-gear:before {
  content: '\ed91';
}
.icon-sync-cross:before {
  content: '\ed92';
}
.icon-ccw:before {
  content: '\ed93';
}
.icon-cw2:before {
  content: '\ed94';
}
.icon-ccw2:before {
  content: '\ed95';
}
.icon-refund:before {
  content: '\ed96';
}
.icon-rewind-5s:before {
  content: '\ed97';
}
.icon-rewind-10s:before {
  content: '\ed98';
}
.icon-forward-10s:before {
  content: '\ed99';
}
.icon-forward-5s:before {
  content: '\ed9a';
}
.icon-history:before {
  content: '\ed9b';
}
.icon-self-timer:before {
  content: '\ed9c';
}
.icon-self-timer2:before {
  content: '\ed9d';
}
.icon-watch:before {
  content: '\ed9e';
}
.icon-watch-handles:before {
  content: '\ed9f';
}
.icon-watch-health:before {
  content: '\eda0';
}
.icon-watch-warning:before {
  content: '\eda1';
}
.icon-watch2:before {
  content: '\eda2';
}
.icon-watch-handles2:before {
  content: '\eda3';
}
.icon-clock1:before {
  content: '\eda4';
}
.icon-alarm:before {
  content: '\eda5';
}
.icon-alarm2:before {
  content: '\eda6';
}
.icon-alarm-plus1:before {
  content: '\eda7';
}
.icon-alarm-minus:before {
  content: '\eda8';
}
.icon-alarm-check:before {
  content: '\eda9';
}
.icon-alarm-cross:before {
  content: '\edaa';
}
.icon-stopwatch:before {
  content: '\edab';
}
.icon-stopwatch-cross:before {
  content: '\edac';
}
.icon-stopwatch-running:before {
  content: '\edad';
}
.icon-download1:before {
  content: '\edae';
}
.icon-upload2:before {
  content: '\edaf';
}
.icon-import:before {
  content: '\edb0';
}
.icon-insert-down:before {
  content: '\edb1';
}
.icon-insert-left:before {
  content: '\edb2';
}
.icon-insert-right:before {
  content: '\edb3';
}
.icon-insert-up:before {
  content: '\edb4';
}
.icon-extract-down:before {
  content: '\edb5';
}
.icon-extract-left:before {
  content: '\edb6';
}
.icon-extract-right:before {
  content: '\edb7';
}
.icon-extract-up:before {
  content: '\edb8';
}
.icon-cli:before {
  content: '\edb9';
}
.icon-bug1:before {
  content: '\edba';
}
.icon-bug2:before {
  content: '\edbb';
}
.icon-file-code1:before {
  content: '\edbc';
}
.icon-file-compressed:before {
  content: '\edbd';
}
.icon-file-music1:before {
  content: '\edbe';
}
.icon-file-video1:before {
  content: '\edbf';
}
.icon-file-picture:before {
  content: '\edc0';
}
.icon-file-preview:before {
  content: '\edc1';
}
.icon-file-chart:before {
  content: '\edc2';
}
.icon-file-chart-bars:before {
  content: '\edc3';
}
.icon-file-spreadsheet1:before {
  content: '\edc4';
}
.icon-file-magnifier:before {
  content: '\edc5';
}
.icon-link:before {
  content: '\edc6';
}
.icon-unlink:before {
  content: '\edc7';
}
.icon-link2:before {
  content: '\edc8';
}
.icon-unlink2:before {
  content: '\edc9';
}
.icon-link-cross:before {
  content: '\edca';
}
.icon-thumbs-up:before {
  content: '\edcb';
}
.icon-thumbs-down:before {
  content: '\edcc';
}
.icon-thumbs-up2:before {
  content: '\edcd';
}
.icon-thumbs-down2:before {
  content: '\edce';
}
.icon-share:before {
  content: '\edcf';
}
.icon-share2:before {
  content: '\edd0';
}
.icon-share3:before {
  content: '\edd1';
}
.icon-loupe:before {
  content: '\edd2';
}
.icon-loup-plus:before {
  content: '\edd3';
}
.icon-loup-minus:before {
  content: '\edd4';
}
.icon-search:before {
  content: '\edd5';
}
.icon-zoom-in:before {
  content: '\edd6';
}
.icon-zoom-out:before {
  content: '\edd7';
}
.icon-magnifier-marquee:before {
  content: '\edd8';
}
.icon-magnifier-move:before {
  content: '\edd9';
}
.icon-find-replace:before {
  content: '\edda';
}
.icon-magnifier-next:before {
  content: '\eddb';
}
.icon-magnifier-prev:before {
  content: '\eddc';
}
.icon-magnifier-check:before {
  content: '\eddd';
}
.icon-magnifier-question:before {
  content: '\edde';
}
.icon-magnifier-user:before {
  content: '\eddf';
}
.icon-magnifier-bug:before {
  content: '\ede0';
}
.icon-magnifier-dollar:before {
  content: '\ede1';
}
.icon-magnifier-euro:before {
  content: '\ede2';
}
.icon-magnifier-pound:before {
  content: '\ede3';
}
.icon-magnifier-yen:before {
  content: '\ede4';
}
.icon-magnifier-ruble:before {
  content: '\ede5';
}
.icon-menu:before {
  content: '\ede6';
}
.icon-ul:before {
  content: '\ede7';
}
.icon-ul2:before {
  content: '\ede8';
}
.icon-ol:before {
  content: '\ede9';
}
.icon-checklist2:before {
  content: '\edea';
}
.icon-checklist-time:before {
  content: '\edeb';
}
.icon-dropdown:before {
  content: '\edec';
}
.icon-eject1:before {
  content: '\eded';
}
.icon-first:before {
  content: '\edee';
}
.icon-backward1:before {
  content: '\edef';
}
.icon-previous:before {
  content: '\edf0';
}
.icon-previous-frame:before {
  content: '\edf1';
}
.icon-play:before {
  content: '\edf2';
}
.icon-pause:before {
  content: '\edf3';
}
.icon-next-frame:before {
  content: '\edf4';
}
.icon-stop:before {
  content: '\edf5';
}
.icon-record:before {
  content: '\edf6';
}
.icon-forward1:before {
  content: '\edf7';
}
.icon-next:before {
  content: '\edf8';
}
.icon-last:before {
  content: '\edf9';
}
.icon-exclamation1:before {
  content: '\edfa';
}
.icon-question:before {
  content: '\edfb';
}
.icon-check1:before {
  content: '\edfc';
}
.icon-cross1:before {
  content: '\edfd';
}
.icon-obelus:before {
  content: '\edfe';
}
.icon-plus:before {
  content: '\edff';
}
.icon-minus:before {
  content: '\ee00';
}
.icon-plus-minus:before {
  content: '\ee01';
}
.icon-percent:before {
  content: '\ee02';
}
.icon-code1:before {
  content: '\ee03';
}
.icon-chevron-up:before {
  content: '\ee04';
}
.icon-chevron-down:before {
  content: '\ee05';
}
.icon-chevron-left:before {
  content: '\ee06';
}
.icon-chevron-right:before {
  content: '\ee07';
}
.icon-expand-vertical:before {
  content: '\ee08';
}
.icon-expand-horizontal:before {
  content: '\ee09';
}
.icon-shrink-vertical:before {
  content: '\ee0a';
}
.icon-shrink-horizontal:before {
  content: '\ee0b';
}
.icon-arrow-up1:before {
  content: '\ee0c';
}
.icon-arrow-down1:before {
  content: '\ee0d';
}
.icon-arrow-left1:before {
  content: '\ee0e';
}
.icon-arrow-right1:before {
  content: '\ee0f';
}
.icon-arrow-ne:before {
  content: '\ee10';
}
.icon-arrow-nw:before {
  content: '\ee11';
}
.icon-arrow-se:before {
  content: '\ee12';
}
.icon-arrow-sw:before {
  content: '\ee13';
}
.icon-split:before {
  content: '\ee14';
}
.icon-merge:before {
  content: '\ee15';
}
.icon-missed-outgoing2:before {
  content: '\ee16';
}
.icon-missed-incoming2:before {
  content: '\ee17';
}
.icon-enter2:before {
  content: '\ee18';
}
.icon-tab:before {
  content: '\ee19';
}
.icon-expand1:before {
  content: '\ee1a';
}
.icon-shrink:before {
  content: '\ee1b';
}
.icon-source:before {
  content: '\ee1c';
}
.icon-sink2:before {
  content: '\ee1d';
}
.icon-expand2:before {
  content: '\ee1e';
}
.icon-shrink2:before {
  content: '\ee1f';
}
.icon-move:before {
  content: '\ee20';
}
.icon-swap-horizontal:before {
  content: '\ee21';
}
.icon-swap-vertical:before {
  content: '\ee22';
}
.icon-swap-calls:before {
  content: '\ee23';
}
.icon-warning:before {
  content: '\ee24';
}
.icon-stop2:before {
  content: '\ee25';
}
.icon-warning-octagon:before {
  content: '\ee26';
}
.icon-warning-circle:before {
  content: '\ee27';
}
.icon-question-circle:before {
  content: '\ee28';
}
.icon-info-circle:before {
  content: '\ee29';
}
.icon-info-octagon:before {
  content: '\ee2a';
}
.icon-menu-circle:before {
  content: '\ee2b';
}
.icon-no:before {
  content: '\ee2c';
}
.icon-no-entry:before {
  content: '\ee2d';
}
.icon-tren-circle:before {
  content: '\ee2e';
}
.icon-chart-circle:before {
  content: '\ee2f';
}
.icon-side-up-circle:before {
  content: '\ee30';
}
.icon-radio-unchecked:before {
  content: '\ee31';
}
.icon-radio-checked:before {
  content: '\ee32';
}
.icon-cross-circle:before {
  content: '\ee33';
}
.icon-check-circle:before {
  content: '\ee34';
}
.icon-download-circle:before {
  content: '\ee35';
}
.icon-upload-circle:before {
  content: '\ee36';
}
.icon-transfer-circle:before {
  content: '\ee37';
}
.icon-code-circle:before {
  content: '\ee38';
}
.icon-arrow-up-circle:before {
  content: '\ee39';
}
.icon-arrow-down-circle:before {
  content: '\ee3a';
}
.icon-arrow-left-circle:before {
  content: '\ee3b';
}
.icon-arrow-right-circle:before {
  content: '\ee3c';
}
.icon-chevron-up-circle:before {
  content: '\ee3d';
}
.icon-chevron-down-circle:before {
  content: '\ee3e';
}
.icon-chevron-left-circle:before {
  content: '\ee3f';
}
.icon-chevron-right-circle:before {
  content: '\ee40';
}
.icon-eject-circle:before {
  content: '\ee41';
}
.icon-first-circle:before {
  content: '\ee42';
}
.icon-backward-circle:before {
  content: '\ee43';
}
.icon-previous-circle:before {
  content: '\ee44';
}
.icon-previous-frame-circle:before {
  content: '\ee45';
}
.icon-play-circle:before {
  content: '\ee46';
}
.icon-pause-circle:before {
  content: '\ee47';
}
.icon-next-frame-circle:before {
  content: '\ee48';
}
.icon-stop-circle:before {
  content: '\ee49';
}
.icon-next-circle:before {
  content: '\ee4a';
}
.icon-forward-circle:before {
  content: '\ee4b';
}
.icon-last-circle:before {
  content: '\ee4c';
}
.icon-equals-circle:before {
  content: '\ee4d';
}
.icon-plus-circle:before {
  content: '\ee4e';
}
.icon-minus-circle:before {
  content: '\ee4f';
}
.icon-cross-circle2:before {
  content: '\ee50';
}
.icon-obelus-circle:before {
  content: '\ee51';
}
.icon-plus-minus-circle:before {
  content: '\ee52';
}
.icon-percent-circle:before {
  content: '\ee53';
}
.icon-infinity-circle:before {
  content: '\ee54';
}
.icon-warning-square:before {
  content: '\ee55';
}
.icon-question-square:before {
  content: '\ee56';
}
.icon-info-square:before {
  content: '\ee57';
}
.icon-menu-square:before {
  content: '\ee58';
}
.icon-exposure:before {
  content: '\ee59';
}
.icon-trend-square:before {
  content: '\ee5a';
}
.icon-chart-square:before {
  content: '\ee5b';
}
.icon-side-up-square:before {
  content: '\ee5c';
}
.icon-checkbox-unchecked:before {
  content: '\ee5d';
}
.icon-cross-square:before {
  content: '\ee5e';
}
.icon-check-square:before {
  content: '\ee5f';
}
.icon-download-square:before {
  content: '\ee60';
}
.icon-upload-square:before {
  content: '\ee61';
}
.icon-transfer-square:before {
  content: '\ee62';
}
.icon-code-square:before {
  content: '\ee63';
}
.icon-arrow-up-square:before {
  content: '\ee64';
}
.icon-arrow-down-square:before {
  content: '\ee65';
}
.icon-arrow-left-square:before {
  content: '\ee66';
}
.icon-arrow-right-square:before {
  content: '\ee67';
}
.icon-chevron-up-square:before {
  content: '\ee68';
}
.icon-chevron-down-square:before {
  content: '\ee69';
}
.icon-chevron-left-square:before {
  content: '\ee6a';
}
.icon-chevron-right-square:before {
  content: '\ee6b';
}
.icon-eject-square:before {
  content: '\ee6c';
}
.icon-first-circle2:before {
  content: '\ee6d';
}
.icon-backward-square:before {
  content: '\ee6e';
}
.icon-previous-square:before {
  content: '\ee6f';
}
.icon-previous-frame-square:before {
  content: '\ee70';
}
.icon-play-square:before {
  content: '\ee71';
}
.icon-pause-square:before {
  content: '\ee72';
}
.icon-next-frame-square:before {
  content: '\ee73';
}
.icon-stop-square:before {
  content: '\ee74';
}
.icon-next-square:before {
  content: '\ee75';
}
.icon-forward-square:before {
  content: '\ee76';
}
.icon-last-square:before {
  content: '\ee77';
}
.icon-equals-square:before {
  content: '\ee78';
}
.icon-plus-square:before {
  content: '\ee79';
}
.icon-minus-square:before {
  content: '\ee7a';
}
.icon-cross-square2:before {
  content: '\ee7b';
}
.icon-obelus-square:before {
  content: '\ee7c';
}
.icon-plus-minus-square:before {
  content: '\ee7d';
}
.icon-percent-square:before {
  content: '\ee7e';
}
.icon-infinity-square:before {
  content: '\ee7f';
}
.icon-crop1:before {
  content: '\ee80';
}
.icon-crop2:before {
  content: '\ee81';
}
.icon-crop-rotate:before {
  content: '\ee82';
}
.icon-cutter:before {
  content: '\ee83';
}
.icon-shrink-square:before {
  content: '\ee84';
}
.icon-expand-square:before {
  content: '\ee85';
}
.icon-expand-square2:before {
  content: '\ee86';
}
.icon-full-screen-enter:before {
  content: '\ee87';
}
.icon-full-screen-exit:before {
  content: '\ee88';
}
.icon-full-screen-enter2:before {
  content: '\ee89';
}
.icon-full-screen-exit2:before {
  content: '\ee8a';
}
.icon-focal-point:before {
  content: '\ee8b';
}
.icon-free-transform:before {
  content: '\ee8c';
}
.icon-grid1:before {
  content: '\ee8d';
}
.icon-grid-cross:before {
  content: '\ee8e';
}
.icon-layers:before {
  content: '\ee8f';
}
.icon-layers-cross:before {
  content: '\ee90';
}
.icon-fold:before {
  content: '\ee91';
}
.icon-unfold:before {
  content: '\ee92';
}
.icon-rulers:before {
  content: '\ee93';
}
.icon-ruler:before {
  content: '\ee94';
}
.icon-priority-increase:before {
  content: '\ee95';
}
.icon-priority-decrease:before {
  content: '\ee96';
}
.icon-bring-forward1:before {
  content: '\ee97';
}
.icon-send-backward:before {
  content: '\ee98';
}
.icon-move-up:before {
  content: '\ee99';
}
.icon-move-down:before {
  content: '\ee9a';
}
.icon-group2:before {
  content: '\ee9b';
}
.icon-group3:before {
  content: '\ee9c';
}
.icon-resize:before {
  content: '\ee9d';
}
.icon-scale-down:before {
  content: '\ee9e';
}
.icon-scale-up:before {
  content: '\ee9f';
}
.icon-squash:before {
  content: '\eea0';
}
.icon-funnel:before {
  content: '\eea1';
}
.icon-flip-vertical:before {
  content: '\eea2';
}
.icon-flip-horizontal:before {
  content: '\eea3';
}
.icon-flip-vertical2:before {
  content: '\eea4';
}
.icon-flip-horizontal2:before {
  content: '\eea5';
}
.icon-round-corner:before {
  content: '\eea6';
}
.icon-angle1:before {
  content: '\eea7';
}
.icon-angle2:before {
  content: '\eea8';
}
.icon-combine:before {
  content: '\eea9';
}
.icon-subtract:before {
  content: '\eeaa';
}
.icon-intersect:before {
  content: '\eeab';
}
.icon-exclude:before {
  content: '\eeac';
}
.icon-align-center-vertical:before {
  content: '\eead';
}
.icon-align-left1:before {
  content: '\eeae';
}
.icon-align-right1:before {
  content: '\eeaf';
}
.icon-align-bottom:before {
  content: '\eeb0';
}
.icon-align-center-horizontal:before {
  content: '\eeb1';
}
.icon-align-top:before {
  content: '\eeb2';
}
.icon-canvas:before {
  content: '\eeb3';
}
.icon-canvas-fit:before {
  content: '\eeb4';
}
.icon-canvas-bottom:before {
  content: '\eeb5';
}
.icon-canvas-center:before {
  content: '\eeb6';
}
.icon-canvas-left:before {
  content: '\eeb7';
}
.icon-canvas-right:before {
  content: '\eeb8';
}
.icon-canvas-top:before {
  content: '\eeb9';
}
.icon-move-bottom-up:before {
  content: '\eeba';
}
.icon-move-top-down:before {
  content: '\eebb';
}
.icon-move-left-right:before {
  content: '\eebc';
}
.icon-move-right-left:before {
  content: '\eebd';
}
.icon-move-bottom-down:before {
  content: '\eebe';
}
.icon-move-top-up:before {
  content: '\eebf';
}
.icon-move-left-left:before {
  content: '\eec0';
}
.icon-move-right-right:before {
  content: '\eec1';
}
.icon-ligature:before {
  content: '\eec2';
}
.icon-format-shapes:before {
  content: '\eec3';
}
.icon-text-color:before {
  content: '\eec4';
}
.icon-format-clear:before {
  content: '\eec5';
}
.icon-text-size:before {
  content: '\eec6';
}
.icon-text-size2:before {
  content: '\eec7';
}
.icon-text-size-increase:before {
  content: '\eec8';
}
.icon-text-size-decrease:before {
  content: '\eec9';
}
.icon-bold1:before {
  content: '\eeca';
}
.icon-italic1:before {
  content: '\eecb';
}
.icon-underline:before {
  content: '\eecc';
}
.icon-strikethrough:before {
  content: '\eecd';
}
.icon-text-down:before {
  content: '\eece';
}
.icon-subscript:before {
  content: '\eecf';
}
.icon-superscript:before {
  content: '\eed0';
}
.icon-highlight:before {
  content: '\eed1';
}
.icon-text-align-left:before {
  content: '\eed2';
}
.icon-text-align-center:before {
  content: '\eed3';
}
.icon-text-align-right:before {
  content: '\eed4';
}
.icon-text-align-justify:before {
  content: '\eed5';
}
.icon-line-spacing:before {
  content: '\eed6';
}
.icon-indent-decrease:before {
  content: '\eed7';
}
.icon-indent-increase:before {
  content: '\eed8';
}
.icon-text-around:before {
  content: '\eed9';
}
.icon-pilcrow:before {
  content: '\eeda';
}
.icon-direction-ltr:before {
  content: '\eedb';
}
.icon-direction-rtl:before {
  content: '\eedc';
}
.icon-page-break:before {
  content: '\eedd';
}
.icon-sort-alpha-asc:before {
  content: '\eede';
}
.icon-sort-alpha-desc:before {
  content: '\eedf';
}
.icon-sort-numeric-asc:before {
  content: '\eee0';
}
.icon-sort-numeric-desc:before {
  content: '\eee1';
}
.icon-sort-time-asc:before {
  content: '\eee2';
}
.icon-sort-time-desc:before {
  content: '\eee3';
}
.icon-sort-size-asc:before {
  content: '\eee4';
}
.icon-sort-size-desc:before {
  content: '\eee5';
}
.icon-sigma:before {
  content: '\eee6';
}
.icon-omega:before {
  content: '\eee7';
}
.icon-sine:before {
  content: '\eee8';
}
.icon-cosine:before {
  content: '\eee9';
}
.icon-border-color:before {
  content: '\eeea';
}
.icon-handwriting:before {
  content: '\eeeb';
}
.icon-fingerprint1:before {
  content: '\eeec';
}
.icon-palm:before {
  content: '\eeed';
}
.icon-hand-grip:before {
  content: '\eeee';
}
.icon-pointer-up:before {
  content: '\eeef';
}
.icon-pointer-down:before {
  content: '\eef0';
}
.icon-pointer-left:before {
  content: '\eef1';
}
.icon-pointer-right:before {
  content: '\eef2';
}
.icon-tap-all:before {
  content: '\eef3';
}
.icon-tap:before {
  content: '\eef4';
}
.icon-tap-two:before {
  content: '\eef5';
}
.icon-gesture-zoom:before {
  content: '\eef6';
}
.icon-gesture-pinch:before {
  content: '\eef7';
}
.icon-swipe-horizontal:before {
  content: '\eef8';
}
.icon-swipe-left:before {
  content: '\eef9';
}
.icon-swipe-right:before {
  content: '\eefa';
}
.icon-swipe-vertical:before {
  content: '\eefb';
}
.icon-swipe-up:before {
  content: '\eefc';
}
.icon-swipe-down:before {
  content: '\eefd';
}
.icon-scroll-horizontal:before {
  content: '\eefe';
}
.icon-scroll-left:before {
  content: '\eeff';
}
.icon-scroll-right:before {
  content: '\ef00';
}
.icon-scroll-vertical:before {
  content: '\ef01';
}
.icon-scroll-up:before {
  content: '\ef02';
}
.icon-scroll-down:before {
  content: '\ef03';
}
.icon-reminder:before {
  content: '\ef04';
}
.icon-horns:before {
  content: '\ef05';
}
.icon-ellipsis1:before {
  content: '\ef06';
}
.icon-menu2:before {
  content: '\ef07';
}
.icon-eye-off:before {
  content: '\ef0e';
}
.icon-eye3:before {
  content: '\ef0f';
}
.icon-eye1:before {
  content: '\ef0d';
}
.icon-send:before {
  content: '\ef0a';
}
.icon-users:before {
  content: '\ef0b';
}
.icon-video:before {
  content: '\ef0c';
}
.icon-log-out:before {
  content: '\ef09';
}
