.form-container {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  width: 100%;
  height: 100%;
  padding: 40px 40px 20px;
  overflow: auto;
  background-color: var(--button-background);
  display: flex;
  align-items: center;
  background-color: white;
}

.form-container label {
  display: block;
}

.form-container h1 {
  line-height: 1em;
}

/* Checkbox CSS BEGIN */

.form-container input[type='checkbox'] {
  margin: 5px;
  margin-left: 10px;
  background-color: #eee;
  margin-right: 10px;
}

/* OUTSIDE BOX */

.form-container input[type='checkbox']:before {
  position: relative;
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid var(--primary-color);
  content: '';
  background: #fff;
  border-radius: 18%;
}

.form-container input[type='checkbox']:not(:disabled):hover:before {
  border-color: var(--primary-color);
}

/* INSIDE BOX */

.form-container input[type='checkbox']:after {
  position: relative;
  display: block;
  left: 2px;
  top: -15px;
  width: 11px;
  height: 11px;
  content: '';
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 80%;
}

.form-container input[type='checkbox']:not(:disabled):hover:after {
  border: 1px solid var(--primary-color);
  background-color: #b3b3b3;
  opacity: 60%;
}

.form-container input[type='checkbox']:checked:after {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  opacity: 60%;
}

.form-container input[type='checkbox']:disabled:after {
  -webkit-filter: opacity(0.4);
}

.form-container input[type='checkbox']:not(:disabled):checked:hover:after {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  opacity: 100%;
}

/* Checkbox CSS END */

/* Radio CSS BEGIN */

.form-container input[type='radio'] {
  margin: 5px 10px;
  background-color: #eee;
}

.form-container input[type='radio']:hover {
  opacity: 50%;
  border: 1px solid var(--primary-color);
}

.form-container input[type='radio']:not(:disabled):hover:before {
  border: 1px solid var(--primary-color);
}

.form-container input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 10%;
  position: relative;
  background-color: black;
  content: '';
  display: inline-block;
  border: 1px solid white;
}

.form-container input[type='radio']:not(:disabled):hover:after {
  border: 1px solid var(--primary-color);
}

.form-container input[type='radio']:checked:after {
  background-color: var(--primary-color);
}

/* Radio CSS END */

.form-container .text-input {
  border-radius: 3px;
  width: 100%;
  padding: 8px 12px;
  font-size: var(--small-font-size);
  color: black;
  vertical-align: middle;
  background: white;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  opacity: 0.6;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.text-input {
  transition: 0.2s ease all;
}
.form-container .text-input:active,
.form-container .text-input:focus {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;

  outline: none; /* Needed on mobile */
}

.form-container .text-block {
  /* margin: 1% 0; */
  font-size: var(--small-font-size);
  border-style: none;
}

.form-container .bold-text {
  font-weight: bold;
}

.form-container .error-text {
  color: var(--error-text-color);
}

.form-container .share-buttons {
  width: 50%;
}

.form-container .form-submit-button {
  box-shadow: none;
  border-radius: 8px;
  border: none;
  background: var(--primary-color);
  opacity: 0.7;
  font-size: var(--large-font-size);
  display: inline-block;
  padding: 15px;
  width: 100%;
  color: var(--text-color);
  transition: 0.2s ease all;
}
.form-container .form-submit-button:hover {
  background: var(--primary-color);
  opacity: 1;
}

.form-container .form-submit-button:active {
  background: var(--primary-color);
}

.form-container .form-container-inner {
  width: 100%;
  height: 90%;
  overflow: auto;
  padding-right: 20px;
}

.justify_b {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;

  opacity: 0.7 !important;
  border: 1px solid gray !important;
}
.margin_l {
  animation-name: animateRight;
  animation-duration: 1s;
  transition: 0.1s linear all;
}

@keyframes animateRight {
  0% {
    margin-right: -18px;
  }

  100% {
    margin-right: 0;
  }
}
