.exit-text {
  margin: 12% 5% 0% 5%;
  color: black;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
}

.offer-form form {
  margin: 10px 0 10px 0;
}

.offer-form input[type='text'],
.offer-form input[type='email'] {
  width: 70vw;
  height: 40px;
  border-radius: 4px;
  border: none;
  margin-bottom: 6px;
  background-color: #efefef;
}

.offer-form input[type='submit'],
.offer-form input[type='reset'] {
  text-align: center;
  margin: 4px 3px 6px 3px;
  border: 2px solid #3175b1;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  height: 42px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.offer-form input[type='submit']:hover,
.offer-form input[type='reset']:hover {
  opacity: 0.7;
}

.offer-form input[type='submit'] {
  width: 44vw;
  color: white;
  background-color: #3175b1;
}

.offer-form input[type='reset'] {
  width: 24vw;
  color: #3175b1;
  background-color: white;
}

.review-container h3 {
  font-size: 20px;
  font-weight: bold;
}

.review-container textarea {
  resize: none;
  width: 90vw;
  height: 90px;
  border: none;
  border-radius: 6px;
  background-color: #efefef;
  overflow-y: scroll;
}
.center-div .MuiRating-sizeLarge {
  font-size: 4rem;
}
