.dashed {
  /* border-style: dashed; */
  display: block;
  background-color: #f8f9fa;
  width: 375px;
  max-width: 100%;
  padding: 20px;
  margin-top: 20px;

  /* dashed border : https://kovart.github.io/dashed-border-generator/*/
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='lightblue' stroke-width='3' stroke-dasharray='7%2c14' stroke-dashoffset='3' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 20px;
}

.center {
  margin: auto;
  text-align: center;
  height: 100%;
}

/* .video-style {
  border: 5px solid #3898ec;
  border-radius: 25px;
  height: 500px;
  width: 300px;
  background-color: black;
  max-width: 100%;
} */

.gallery {
  border: 3px solid #212121;
  border-radius: 50px;
  height: 75px;
  width: 75px;
  background-color: black;
}

.circle-button {
  border: none;
  border-radius: 50px;
  height: 100px;
  width: 100px;
  background-color: #3898ec;
}

.div-block {
  width: 400px;
  /* height: 600px; */
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 28px;
}

.demo-text {
  text-align: left;
  padding-top: 10px;
}

.encode-text {
  margin-left: 15%;
}

.upload-url-container {
  width: 375px;
  max-width: 100%;
  margin-top: 20px;

  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0px;
  }

  .div-block {
    width: 100%;
  }

  .encode-text {
    margin-left: 0;
  }
}

.folder {
  transition: all 0.2s ease-in;
  margin-top: 25px;
  margin-bottom: 25px;
}
.folder__back {
  position: relative;
  width: 100px;
  height: 80px;
  background: #4786ff;
  border-radius: 0px 5px 5px 5px;
}
.folder__back::after {
  position: absolute;
  bottom: 98%;
  left: 0;
  content: '';
  width: 30px;
  height: 10px;
  background: #4786ff;
  border-radius: 5px 5px 0 0;
}
.folder__back .paper {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 10%);
  width: 70%;
  height: 80%;
  background: #e6e6e6;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.folder__back .paper:nth-child(2) {
  background: #f2f2f2;
  width: 80%;
  height: 70%;
}
.folder__back .paper:nth-child(3) {
  background: #fff;
  width: 90%;
  height: 60%;
}
.folder__back .folder__front {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #70a1ff;
  border-radius: 5px;
  transform-origin: bottom;
  transition: all 0.3s ease-in-out;
}
.folder:hover {
  transform: translateY(-8px);
}
.folder:hover .paper {
  transform: translate(-50%, 0%);
}
.folder:hover .folder__front {
  transform: skew(15deg) scaleY(0.6);
}
.folder:hover .right {
  transform: skew(-15deg) scaleY(0.6);
}
