@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* NEW SIGNIN IN BEGIN  */

.SignIn,
.SignUp,
.SignOut {
  font-family: Epilogue, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  position: relative;
  background-color: white;
  /* background-color: rgb(226, 244, 253); */
}

.center_container {
  border-radius: 20px;
  width: 70vw;
  padding: 50px 50px;
  background-color: white;
}
@media screen and (max-width: 767px) {
  .center_container {
    padding: 0px;
  }
}

.logo {
  height: 30px;
  top: 10px;
  left: 30px;
  position: absolute;
  z-index: 5;
}
@media screen and (max-width: 767px) {
  .logo {
    height: 25px;
  }
}

.logo-img {
  width: auto;
  height: 100%;
}

.blue-highlight {
  margin-left: 0px;
  padding-right: 8px;
  padding-left: 8px;
  border-top: 8px solid rgb(226, 244, 253);
  border-bottom: 2px solid rgb(226, 244, 253);
  border-radius: 8px;
  background-color: rgb(226, 244, 253);
  color: rgb(32, 149, 248);
}

#left {
  width: 50%;
}

form.login-form {
  position: relative;
  display: inline-flex;
}

button.reset-password {
  position: absolute;
  top: 0;
  right: 0;
}

a.special-link {
  color: rgb(0, 147, 240);
  text-decoration: none;
}

a.special-link-red {
  color: rgb(56, 152, 236);
  text-decoration: none;
}

input#login.text-input {
  appearance: none;
  background-color: #f3f3f4;
  border: 1px solid transparent;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-radius: 4px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
  color: #0d0c22;
  font-family: Epilogue, sans-serif;
  margin: 10px 0px 5px 0px;
  font-size: 18px;
  font-weight: 400;
  height: 30px;
  line-height: 25px;
  margin-right: 8px;
  outline: none;
  padding: 30px 16px;
  transition: background-color 200ms ease 0s, outline 200ms ease 0s,
    color 200ms ease 0s, box-shadow 200ms ease 0s,
    -webkit-box-shadow 200ms ease 0s;
  width: 100%;
}
@media screen and (max-width: 767px) {
  input#login.text-input {
    font-size: 15px;
    line-height: 15px;
    padding: 25px 16px;
  }
}

input#login.text-input::placeholder {
  color: #858585;
}

/* SIGNIN BUTTONS */

.button.signin,
.button.create,
.button.google,
.button.secondary,
.button.back {
  background-color: rgb(56, 152, 236);
  text-decoration: none;
  display: inline-block;
  color: white;
  cursor: pointer;
  font-family: Epilogue, sans-serif;
  width: 100%;
  margin-top: 20px;
  padding: 15px 0px 10px;
  border: 3px solid rgb(56, 152, 236);
  border-radius: 4px;
  transition: transform 200ms ease 0s all, -webkit-transform 200ms ease 0s all;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.2px;
}
.button.signin:hover,
.button.create:hover,
.button.google:hover,
.button.back:hover {
  /* border-width: 4px;
  border-radius: 8px; */
  transform: scale(1.005);
}
@media screen and (max-width: 767px) {
  .button.signin,
  .button.create,
  .button.google,
  .button.secondary {
    font-size: 15px;
    line-height: 15px;
    padding: 20px 0 15px;
  }
}

.button.back {
  background-color: rgb(243, 243, 244);
  color: black;
  border: 3px solid rgb(243, 243, 244);
  width: 15%;
}

.button.secondary:hover {
  transform: scale(1.05);
  border: none;
}

.button.create {
  background-color: white;
  color: rgb(56, 152, 236);
  border: 3px solid rgb(56, 152, 236);
}

.button.google {
  background-color: white;
  color: #3d4043;
  margin-top: 5px;
  border: 2px solid rgba(193, 193, 193, 0.556);
}

.button.next {
  background-color: rgb(56, 152, 236);
  text-decoration: none;
  display: inline-block;
  color: white;
  cursor: pointer;

  font-family: Epilogue, sans-serif;
  margin-top: 20px;
  padding: 5px 10px;
  border-radius: 50%;
  border: 3px solid rgb(56, 152, 236);
  transition: transform 200ms ease 0s, -webkit-transform 200ms ease 0s;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.button.next:hover {
  border-width: 4px;
  border-radius: 8px;
  transform: scale(1.05);
}

.button.secondary {
  background-color: white;
  text-decoration: underline;
  color: rgb(56, 152, 236);

  margin-top: 20px;
  border: none;
}

.error_text {
  color: red;
}

/*  NEW SIGNIN END  */
